const EditorPluginStyle = {
  input_plugin: {
    border: "0",
    "border-bottom": "1px solid black",
    "background-color": "#F0F0F0",
    resize: "horizontal",
    "line-height": "1rem",
    "font-family": "monospace, monospace",
    overflow: "hidden",
    width: "100%",
    outline: "none",
    "box-sizing": "border-box",
    "font-size": "18px",
  },
  client_phone_plugin: {
    border: "0",
    "border-bottom": "1px solid black",
    "background-color": "#F0F0F0",
    resize: "horizontal",
    "line-height": "1rem",
    "font-family": "monospace, monospace",
    overflow: "hidden",
    width: "100%",
    outline: "none",
    "box-sizing": "border-box",
    "font-size": "18px",
  },
  client_name_plugin: {
    border: "0",
    "border-bottom": "1px solid black",
    "background-color": "#F0F0F0",
    resize: "horizontal",
    "line-height": "1rem",
    "font-family": "monospace, monospace",
    overflow: "hidden",
    width: "100%",
    outline: "none",
    "box-sizing": "border-box",
    "font-size": "18px",
  },
  date_plugin: {
    "text-align": "center",
    border: "0",
    "border-bottom": "1px solid black",
    "background-color": "#F0F0F0",
    "font-family": "monospace, monospace",
    width: "100%",
    resize: "horizontal",
    overflow: "hidden",
    "line-height": "1rem",
    "box-sizing": "border-box",
    padding: "0",
    "font-size": "18px",
  },
  signature_plugin: {
    "background-color": "#F0F0F0",
    border: "1px solid gray",
    "padding-left": "10px",
    "padding-right": "10px",
    "border-radius": "5px",
    "box-sizing": "border-box",
    "font-size": "18px",
  },
  checkbox_plugin: {
    display: "inline-block",
    "line-height": "1.5rem",
    "font-size": "18px",
    "vertical-align": "top",
    "box-sizing": "border-box",
  },
  textarea_plugin: {
    "font-size": "18px",
    "background-color": "#F0F0F0",
    width: "100%",
    "font-family": "monospace, monospace",
    overflow: "hidden",
    "box-sizing": "border-box",
    resize: "vertical",
  },
};

export const getEditorPluginStyleAsText = (className) => {
  let cssText = "";

  if (EditorPluginStyle[className]) {
    for (const [key, value] of Object.entries(EditorPluginStyle[className])) {
      cssText += `${key}: ${value};`;
    }
    return cssText;
  } else {
    console.error(
      "Cannot get EditorPlugin style as text since the className does not exist."
    );
    return null;
  }
};
