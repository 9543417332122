import React, { useEffect, useRef, useState } from "react";
import SlProgressBar from "@shoelace-style/shoelace/dist/react/progress-bar";
import { useLoading } from "../../context/LoadingContext/index";

const DEFAULT_PROGRESS_BAR_VALUE = 5;

const GlobalSpinner = (props) => {
  const { loading, setLoading } = useLoading();
  const loadingRef = useRef(loading);
  const [innerLoading, setInnerLoading] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(
    DEFAULT_PROGRESS_BAR_VALUE
  );

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const delayLoading = async () => {
    await timeout(500);

    if (loadingRef.current) setInnerLoading(true);
    else setInnerLoading(false);
  };

  useEffect(() => {
    loadingRef.current = loading;

    if (loading) {
      setProgressBarValue(DEFAULT_PROGRESS_BAR_VALUE);
      delayLoading();
    } else {
      setInnerLoading(false);
    }
  }, [loading]);

  return (
    <div className={props.className}>
      <div
        style={{
          display: "block",
          visibility: !innerLoading ? "hidden" : "visible",
          position: "sticky",
          top: 0,
        }}
      >
        <SlProgressBar
          value={progressBarValue}
          indeterminate
          style={{ "--height": "6px" }}
        />
      </div>
      {props.children}
    </div>
  );
};

export default GlobalSpinner;
