import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState, useContext } from "react";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Progress from "../Progress/Progress";
import api_endpoints from "../../api/index";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import AuthContext from "../../context/AuthContext";
import { CircularProgress, colors } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const steps = [
  "Choisir un ou des formulaires à transférer",
  "Transfert en cours",
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormsList = ({
  clientsFormsData,
  setSelectedForms,
  selectedForms,
  override,
}) => {
  const toggleChecked = (form_name) => {
    const currentIndex = selectedForms.indexOf(form_name);
    const newChecked = [...selectedForms];

    if (currentIndex === -1) {
      newChecked.push(form_name);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedForms(newChecked);
  };

  const formExistsInNewApp = (old_form_name) => {
    const form_name = old_form_name.replace("formualire", "formulaire");
    if (clientsFormsData.client_forms_filled.includes(form_name)) return 2;
    if (clientsFormsData.new_forms_list.includes(form_name)) return 1;
    return 0;
  };

  const ChipStatus = (type) => {
    let label = "";
    let color = "";

    switch (type) {
      case 1:
        label = "Ce formulaire n'a pas été transféré";
        color = "warning";
        break;

      case 2:
        label =
          "Ce formulaire a déjà été transféré ou le client possède des données";
        color = "success";
        break;

      case 0:
        label = "Ce formulaire n'existe pas sur cette application";
        color = "error";
        break;

      default:
        label = "Incapable de trouver l'état du formulaire";
        color = "error";
        break;
    }

    return <Chip color={color} variant="contained" label={label} />;
  };

  return (
    <List className="flex flex-1 flex-col overflow-y-auto">
      {clientsFormsData?.old_forms_filled_by_client.map((form_name, index) => {
        const formStatus = formExistsInNewApp(form_name);

        if (formStatus === 0) return;

        return (
          <React.Fragment key={form_name}>
            <ListItem disablePadding>
              <ListItemButton
                dense
                role={undefined}
                onClick={() => toggleChecked(form_name)}
                disabled={
                  formStatus === 1
                    ? false
                    : formStatus === 0
                    ? true
                    : formStatus === 2 && override
                    ? false
                    : true
                }
                className="flex w-full"
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedForms.indexOf(form_name) !== -1}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={form_name
                    .toUpperCase()
                    .replace("FORMULAIRE", "")
                    .replace("FORMUALIRE", "")}
                />
              </ListItemButton>
              {ChipStatus(formStatus)}
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

const FormsTransfer = ({ selectedForms, client, setTransfering }) => {
  const [transferIndexError, setTransferIndexError] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [transferIndexSuccess, setTransferIndexSuccess] = useState([]);
  const [transferFailed, setTransferFailed] = useState({});
  const authContext = useContext(AuthContext);

  useEffect(() => {
    transferData();
  }, []);

  const transferData = async () => {
    setTransfering(true);
    let index = 0;
    let data = null;

    for (let form of selectedForms) {
      try {
        let form_data = await fetchForm(form.replace("formulaire", ""));
        let old_form_data = await fetchOldFormData(form);

        const wrapper = createDOM(form_data.body);

        if (form_data && wrapper && old_form_data) {
          data = setDataNewForm(wrapper, old_form_data, form);

          if (data?.invalid || !data) {
            throw new Error(
              "Le nombre de champs ne concorde pas entre l'ancien et le nouveau formulaire"
            );
          }
          await saveData(JSON.stringify(data), form);
        } else {
          let errorIndex = transferIndexError;
          errorIndex.push(index);
          setTransferIndexError([...errorIndex]);
        }

        let successIndex = transferIndexSuccess;
        successIndex.push(index);
        setTransferIndexSuccess([...successIndex]);

        transferIndexSuccess;
      } catch (e) {
        let errorIndex = index;
        setTransferFailed((prevState) => ({
          ...prevState,
          [errorIndex]: data?.invalid ? (
            <label className="font-semibold">
              <span className="text-red-500">{data.oldCount}</span> champs{" "}
              <span className="text-red-500">{data.type}</span> dans l'ancien et{" "}
              <span className="text-red-500">{data.newCount}</span> dans le
              nouveau
            </label>
          ) : (
            "Une erreur est survenue"
          ),
        }));
        console.error(e);
      }

      ++index;
    }
    setTransfering(false);
  };

  const createDOM = (HTMLString) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = HTMLString;
    return wrapper;
  };

  const countNumberFieldsText = (oldData, newFieldsLength) => {
    let validTypes = ["text", "textarea"];
    let dataToLoop = oldData.inputs;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validTypes.includes(value.type)) {
        count++;
      }
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Textarea/Input",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsCheckbox = (oldData, newFieldsLength) => {
    let validTypes = ["checkbox", "radio"];

    let dataToLoop = oldData.inputs;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validTypes.includes(value.type)) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Checkbox/Radio",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsSignature = (oldData, newFieldsLength) => {
    let validType = "image";
    let dataToLoop = oldData.signatures;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validType === value.type) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Signatures",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsDrawings = (oldData, newFieldsLength) => {
    let validType = "drawing";
    let dataToLoop = oldData.drawings;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validType === value.type) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Dessins",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const setDataNewForm = (element, old_form_data) => {
    let index = 0;
    let dataToSave = {};
    let textareas = element.querySelectorAll("textarea[id]");
    let inputs = element.querySelectorAll("input[id][type=checkbox]");
    let signatures = element.querySelectorAll(
      "button[id][data-type=signature]"
    );
    let drawings = element.querySelectorAll("img[id]");

    let clientData = old_form_data.client_data;
    let oldData = JSON.parse(old_form_data.forms_fields.old_form_fields);
    let inputsOrderArray = getDictionaryOrderArray(oldData.inputs);
    let drawingsOrderArray = getDictionaryOrderArray(oldData.drawings);
    let signaturesOrderArray = getDictionaryOrderArray(oldData.signatures);

    const countValues = [
      countNumberFieldsText(oldData, textareas.length),
      countNumberFieldsCheckbox(oldData, inputs.length),
      countNumberFieldsDrawings(oldData, drawings.length),
      countNumberFieldsSignature(oldData, signatures.length),
    ];

    for (const count of countValues) {
      if (typeof count !== "boolean") {
        return count;
      }
    }

    for (let input of inputsOrderArray) {
      let fieldInfo = oldData.inputs[oldData.inputs[input]];
      let fieldData = clientData[fieldInfo.id];

      if (fieldInfo.type === "text" || fieldInfo.type === "textarea") {
        if (fieldData) {
          if (!textareas[index]) continue;

          dataToSave[textareas[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    for (let input of inputsOrderArray) {
      let fieldInfo = oldData.inputs[oldData.inputs[input]];
      let fieldData = clientData[fieldInfo.name];

      if (fieldInfo.type === "checkbox") {
        if ((fieldData === 1 || fieldData === "1") && inputs[index].id) {
          if (!inputs[index]) continue;

          dataToSave[inputs[index].id] = "on";
        }
        index++;
      } else if (fieldInfo.type === "radio") {

        if (clientData[fieldInfo.name] == fieldInfo.value) {
          if (!inputs[index]) continue;

          dataToSave[inputs[index].id] = "on";
        }
        index++;
      }
    }

    index = 0;

    for (let drawing of drawingsOrderArray) {
      let fieldInfo = oldData.drawings[oldData.drawings[drawing]];

      if (fieldInfo.type === "drawing") {
        let fieldData = clientData[fieldInfo.id];

        if (fieldData) {
          if (!drawings[index]) continue;

          dataToSave[drawings[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    for (let signature of signaturesOrderArray) {
      let fieldInfo = oldData.signatures[oldData.signatures[signature]];
      let fieldData = clientData[fieldInfo.id];

      if (fieldInfo.type === "image") {
        if (fieldData) {
          if (!signatures[index]) continue;

          dataToSave[signatures[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    return dataToSave;
  };

  const saveData = async (data, formName) => {
    const response = await api_endpoints.transfer_form(
      data,
      formName,
      client.numero,
      authContext.authContext.accessToken.id
    );

    if (!response.status === 200) throw new Error("Erreur lors du transfert");
  };

  const getDictionaryOrderArray = (dictionary) => {
    let orderArray = [];

    for (const [key, value] of Object.entries(dictionary)) {
      if (!isNaN(parseInt(key))) orderArray.push(parseInt(key));
      else break;
    }
    return orderArray;
  };

  const fetchForm = async (form_name) => {
    try {
      const response = await api_endpoints.get_form_by_name(form_name);

      if (response.status === 200) {
        return JSON.parse(response.data)[0][0];
      }

      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const fetchOldFormData = async (form_name) => {
    try {
      const response = await api_endpoints.get_old_form_data(
        form_name,
        client.numero
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      {selectedForms.map((form_name, index) => (
        <React.Fragment key={form_name}>
          <div className="flex w-full items-center py-2 flex-col">
            <div className="w-full">
              {form_name
                .replace("formulaire", "")
                .replace("formualire", "")
                .toUpperCase()}
            </div>
            <div className="w-full">
              {transferFailed[index] ? (
                <>
                  <div>{transferFailed[index]}</div>
                </>
              ) : transferIndexSuccess.includes(index) ? (
                <div className="text-green-500">
                  Formulaire transféré avec succès
                </div>
              ) : (
                "En traitement...."
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const FormsTransferModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [clientsFormsData, setClientsFormsData] = useState({});
  const [selectedForms, setSelectedForms] = useState([]);
  const [transfering, setTransfering] = useState(false);
  const [override, setOverride] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (props.open === true) fetchClientFormsTransfer();
    else if (props.open === false) {
      setClientsFormsData([]);
      setSelectedForms([]);
      setActiveStep(0);
      setTransfering(false);
      setLoading(true);
    }
  }, [props.open]);

  const fetchClientFormsTransfer = async () => {
    setLoading(true);

    try {
      const response = await api_endpoints.get_client_forms_transfer(
        props.client.numero
      );

      if (response.status === 200) {
        const data = JSON.parse(response.data);
        setClientsFormsData(data);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleNextStep = () => {
    if (activeStep == 1) {
      handleClose();
      window.location.reload();
    }

    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"xl"}
      PaperProps={{
        sx: {
          height: "90vh",
        },
      }}
    >
      <DialogTitle>
        Transfert de données pour{" "}
        <strong className="font-extrabold">
          {props.client?.nom} ({props.client.numero})
        </strong>
      </DialogTitle>
      <Progress loading={loading}>
        <div className="mx-28">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <DialogContent className="my-8">
          {activeStep === 0 &&
          clientsFormsData?.old_forms_filled_by_client?.length > 0 ? (
            <FormsList
              clientsFormsData={clientsFormsData}
              setSelectedForms={setSelectedForms}
              selectedForms={selectedForms}
              override={override}
            />
          ) : activeStep === 1 && selectedForms.length > 0 ? (
            <FormsTransfer
              setTransfering={setTransfering}
              client={props.client}
              selectedForms={selectedForms}
            />
          ) : (
            <></>
          )}
        </DialogContent>
      </Progress>
      <DialogActions className="w-full justify-between">
        <div className="flex flex-1">
          {activeStep === 0 ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        alert(
                          "Attention ! Vous allez écraser les données des clients. Cette action est irréversible."
                        );
                      }
                      setOverride(e.target.checked);
                    }}
                  />
                }
                label="Écraser les formulaires existants"
              />
            </FormGroup>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-1 justify-end space-x-4">
          {transfering ? <CircularProgress size={25} /> : <></>}
          {activeStep === 0 ? (
            <Button onClick={handleClose} variant="outlined" color="error">
              Annuler
            </Button>
          ) : (
            <></>
          )}
          <Button
            onClick={handleNextStep}
            variant="contained"
            color="primary"
            disabled={
              (activeStep === 0 && selectedForms.length === 0) || transfering
                ? true
                : false
            }
          >
            {activeStep === 0 ? "Transférer" : "Terminer"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FormsTransferModal;
