import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState, useContext } from "react";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Progress from "../Progress/Progress";
import api_endpoints from "../../api/index";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AuthContext from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const steps = ["Recherche de tous les clients", "Transfert en cours"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  {
    label: "Numéro - Nom",
    dataKey: "clientNumber",
  },
  {
    label: "Résultat",
    dataKey: "formsDone",
    width: 350,
  },
];

const FormsTransfer = ({ clients, setTransfering }) => {
  const authContext = useContext(AuthContext);
  const [clientsStatus, setClientsStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientCountFetchedData, setClientCountFetchedData] = useState(0);
  const [clientTransferedCount, setClientsTransferedCount] = useState(0);
  const [totalClients, setTotalClients] = useState(0);

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    if (row.formsList.length === row.formsDone)
      return <div>{row.clientNumber} complété</div>;

    return (
      <React.Fragment key={row.clientNumber}>
        <TableCell>
          <div>
            <span className="mr-2 text-lg font-semibold">
              {row.clientNumber}{" "}
            </span>{" "}
            {row.clientName}
          </div>
          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Plus de détails
            </AccordionSummary>
            <AccordionDetails>
              {row?.formsList?.map((form_name, index) => (
                <React.Fragment key={form_name}>
                  <div className="flex w-full flex-col items-center py-2">
                    <div className="w-full">
                      {form_name
                        .replace("formulaire", "")
                        .replace("formualire", "")
                        .toUpperCase()}
                    </div>
                    <div className="w-full">
                      {row.indexError.includes(index) ? (
                        <>
                          <div>{row.errorMessage[index]}</div>
                        </>
                      ) : row.indexSucces.includes(index) ? (
                        <div className="text-green-500">
                          Formulaire transféré avec succès
                        </div>
                      ) : (
                        <CircularProgress size={15} />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </TableCell>
        <TableCell>
          {row.done && row.formsList.length > 0 ? (
            <div
              className={
                row.formsList.length !== row.formsDone
                  ? "text-red-500"
                  : "text-green-500"
              }
            >
              <span className="text-xl font-semibold">{row.formsDone}</span>{" "}
              formulaires transférés sur{" "}
              <span className="text-xl font-semibold">
                {row.formsList.length}
              </span>
            </div>
          ) : row.done && row.formsList?.length === 0 ? (
            "Aucun formulaire à transférer"
          ) : (
            <CircularProgress size={15} />
          )}
        </TableCell>
      </React.Fragment>
    );
  }

  useEffect(() => {
    initClientsStatus();
  }, []);

  useEffect(() => {
    if (clientsStatus.length > 0) setLoading(false);

    if (clientsStatus.length > 0 && loading) transferData();
  }, [clientsStatus]);

  const initClientsStatus = async () => {
    setLoading(true);
    let data = [];
    for (const client of clients) {
      const formsListToTransfer = await fetchClientFormsTransfer(client.numero);
      let transferDone = true;

      if (formsListToTransfer.old_forms_filled_by_client.length > 0) {
        transferDone = false;
        data.push({
          clientNumber: client.numero,
          clientName: client.nom,
          done: transferDone,
          formsDone: 0,
          indexSucces: [],
          indexError: [],
          errorMessage: [],
          formsList: [...formsListToTransfer.old_forms_filled_by_client],
        });
      }

      setClientCountFetchedData((prevState) => prevState + 1);
    }

    setTotalClients(data.length);
    setClientsStatus(data);
  };

  const fetchClientFormsTransfer = async (client_number) => {
    try {
      const response = await api_endpoints.get_client_forms_transfer(
        client_number
      );

      if (response.status === 200) {
        return JSON.parse(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const transferData = async () => {
    let data = null;

    for (let j = 0; j < clientsStatus.length; j++) {
      let doneCount = 0;
      let index = 0;
      if (clientsStatus[j].formsList.length === 0) continue;

      for (let i = 0; i < clientsStatus[j].formsList.length; i++) {
        let wrapper = null;
        try {
          let form_data = await fetchForm(
            clientsStatus[j].formsList[i].replace("formulaire", "")
          );

          let old_form_data = await fetchOldFormData(
            clientsStatus[j].clientNumber,
            clientsStatus[j].formsList[i]
          );

          wrapper = createDOM(form_data?.body);

          if (form_data && wrapper && old_form_data) {
            data = setDataNewForm(
              wrapper,
              old_form_data,
              clientsStatus[j].formsList[i]
            );
            if (data?.invalid || !data) {
              throw new Error(
                "Le nombre de champs ne concorde pas entre l'ancien et le nouveau formulaire"
              );
            }
            await saveData(
              JSON.stringify(data),
              clientsStatus[j].formsList[i],
              clientsStatus[j].clientNumber
            );
          }

          let clientIndex = j;
          let successIndex = index;
          let newStateValue = [...clientsStatus];
          newStateValue[clientIndex].indexSucces.push(successIndex);
          newStateValue[clientIndex].errorMessage.push("");
          newStateValue.splice(clientIndex, 1, newStateValue[clientIndex]);
          setClientsStatus([...newStateValue]);
          doneCount++;
        } catch (e) {
          let clientIndex = j;
          let errorIndex = index;
          let newStateValue = [...clientsStatus];
          newStateValue[clientIndex].indexError.push(errorIndex);

          newStateValue[clientIndex].errorMessage.push(
            data?.invalid ? (
              <label className="font-semibold">
                <span className="text-red-500">{data.oldCount}</span> champs{" "}
                <span className="text-red-500">{data.type}</span> dans l'ancien
                et <span className="text-red-500">{data.newCount}</span> dans le
                nouveau
              </label>
            ) : (
              <label className="text-red-500">Une erreur est survenue</label>
            )
          );

          newStateValue.splice(clientIndex, 1, newStateValue[clientIndex]);
          setClientsStatus([...newStateValue]);
          console.error(e);
        }
        ++index;
      }

      let newStateValue = [...clientsStatus];
      newStateValue[j].formsDone = doneCount;
      newStateValue[j].done = true;
      setClientsStatus([...newStateValue]);
      setClientsTransferedCount((prevState) => prevState + 1);
    }

    setTransfering(false);
  };

  const createDOM = (HTMLString) => {
    if (!HTMLString) return null;

    const wrapper = document.createElement("div");
    wrapper.innerHTML = HTMLString;
    return wrapper;
  };

  const countNumberFieldsText = (oldData, newFieldsLength) => {
    let validTypes = ["text", "textarea"];
    let dataToLoop = oldData.inputs;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validTypes.includes(value.type)) {
        count++;
      }
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Textarea/Input",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsCheckbox = (oldData, newFieldsLength) => {
    let validTypes = ["checkbox", "radio"];

    let dataToLoop = oldData.inputs;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validTypes.includes(value.type)) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Checkbox/Radio",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsSignature = (oldData, newFieldsLength) => {
    let validType = "image";
    let dataToLoop = oldData.signatures;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validType === value.type) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Signatures",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const countNumberFieldsDrawings = (oldData, newFieldsLength) => {
    let validType = "drawing";
    let dataToLoop = oldData.drawings;
    let count = 0;

    for (const [key, value] of Object.entries(dataToLoop)) {
      if (typeof value !== "string" && validType === value.type) count++;
    }

    return newFieldsLength === count
      ? true
      : {
          type: "Dessins",
          oldCount: count,
          newCount: newFieldsLength,
          invalid: true,
        };
  };

  const setDataNewForm = (element, old_form_data) => {
    let index = 0;
    let dataToSave = {};
    let textareas = element.querySelectorAll("textarea[id]");
    let inputs = element.querySelectorAll("input[id][type=checkbox]");
    let signatures = element.querySelectorAll(
      "button[id][data-type=signature]"
    );
    let drawings = element.querySelectorAll("img[id]");

    let clientData = old_form_data.client_data;
    let oldData = JSON.parse(old_form_data.forms_fields.old_form_fields);
    let inputsOrderArray = getDictionaryOrderArray(oldData.inputs);
    let drawingsOrderArray = getDictionaryOrderArray(oldData.drawings);
    let signaturesOrderArray = getDictionaryOrderArray(oldData.signatures);

    const countValues = [
      countNumberFieldsText(oldData, textareas.length),
      countNumberFieldsCheckbox(oldData, inputs.length),
      countNumberFieldsDrawings(oldData, drawings.length),
      countNumberFieldsSignature(oldData, signatures.length),
    ];

    for (const count of countValues) {
      if (typeof count !== "boolean") {
        return count;
      }
    }

    for (let input of inputsOrderArray) {
      let fieldInfo = oldData.inputs[oldData.inputs[input]];
      let fieldData = clientData[fieldInfo.id];

      if (fieldInfo.type === "text" || fieldInfo.type === "textarea") {
        if (fieldData) {
          if (!textareas[index]) continue;

          dataToSave[textareas[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    for (let input of inputsOrderArray) {
      let fieldInfo = oldData.inputs[oldData.inputs[input]];
      let fieldData = clientData[fieldInfo.name];

      if (fieldInfo.type === "checkbox") {
        if ((fieldData === 1 || fieldData === "1") && inputs[index].id) {
          if (!inputs[index]) continue;

          dataToSave[inputs[index].id] = "on";
        }
        index++;
      } else if (fieldInfo.type === "radio") {
        if (clientData[fieldInfo.name] === fieldInfo.value) {
          if (!inputs[index]) continue;

          dataToSave[inputs[index].id] = "on";
        }
        index++;
      }
    }

    index = 0;

    for (let drawing of drawingsOrderArray) {
      let fieldInfo = oldData.drawings[oldData.drawings[drawing]];
      let fieldData = clientData[oldData.drawings[drawing]];

      if (fieldInfo.type === "drawing") {
        if (fieldData) {
          if (!drawings[index]) continue;

          dataToSave[drawings[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    for (let signature of signaturesOrderArray) {
      let fieldInfo = oldData.signatures[oldData.signatures[signature]];
      let fieldData = clientData[fieldInfo.id];

      if (fieldInfo.type === "image") {
        if (fieldData) {
          if (!signatures[index]) continue;

          dataToSave[signatures[index].id] = fieldData;
        }
        index++;
      }
    }

    index = 0;

    return dataToSave;
  };

  const saveData = async (data, formName, client_number) => {
    const response = await api_endpoints.transfer_form(
      data,
      formName,
      client_number,
      authContext.authContext.accessToken.id
    );

    if (!response.status === 200) throw new Error("Erreur lors du transfert");
  };

  const getDictionaryOrderArray = (dictionary) => {
    let orderArray = [];

    for (const [key, value] of Object.entries(dictionary)) {
      if (!isNaN(parseInt(key))) orderArray.push(parseInt(key));
      else break;
    }
    return orderArray;
  };

  const fetchForm = async (form_name) => {
    try {
      const response = await api_endpoints.get_form_by_name(form_name);

      if (response.status === 200) {
        return JSON.parse(response.data)[0][0];
      }

      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const fetchOldFormData = async (client_number, form_name) => {
    try {
      const response = await api_endpoints.get_old_form_data(
        form_name,
        client_number
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col">
      {loading ? (
        <div className="flex flex-1 justify-center text-center text-xl">
          <div className="mr-2 text-red-500">{clientCountFetchedData}</div>{" "}
          clients sont initialisés sur{" "}
          <div className="ml-2 text-red-500">{clients.length}</div>
        </div>
      ) : (
        <></>
      )}
      <Progress loading={loading} showMassiveTransferMessage={true}>
        <div className="text-xl font-semibold">
          Total de clients à transférer :{" "}
          <span className="text-green-500">{totalClients}</span>
        </div>
        <div className="text-xl font-semibold">
          Nombre de clients traités :{" "}
          <span className="text-blue-500">{clientTransferedCount}</span>
        </div>
        <div className="flex h-full w-full flex-1">
          <Paper className="flex h-full w-full flex-1">
            <TableVirtuoso
              data={clientsStatus}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
            />
          </Paper>
        </div>
      </Progress>
    </div>
  );
};

const FormsMassiveTransferModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [transfering, setTransfering] = useState(true);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (props.open === true) fetchClients();
    else if (props.open === false) {
      setClients([]);
      setActiveStep(0);
      setTransfering(false);
      setLoading(true);
    }
  }, [props.open]);

  const fetchClients = async () => {
    setTransfering(true);
    setLoading(true);
    try {
      const response = await api_endpoints.get_all_clients();

      if (response.status === 200) {
        setClients(response.data.clients);
        handleNextStep();
      }
    } catch (e) {}
    setLoading(false);
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleNextStep = () => {
    if (activeStep == 1) {
      handleClose();
      window.location.reload();
    }

    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      fullScreen
      className=".printDialog"
    >
      <DialogTitle>Transfert de données massive</DialogTitle>
      <Progress loading={loading} showMassiveTransferMessage={true}>
        <div className="mx-28">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <DialogContent className=".printDialog">
          {activeStep === 1 && clients.length > 0 ? (
            <FormsTransfer setTransfering={setTransfering} clients={clients} />
          ) : (
            <></>
          )}
        </DialogContent>
      </Progress>
      <DialogActions className="w-full justify-between">
        <div className="flex flex-1 justify-end space-x-4 ">
          {transfering ? (
            <>
              <CircularProgress size={25} />
            </>
          ) : (
            <></>
          )}
          <Button
            onClick={handleNextStep}
            variant="contained"
            color="primary"
            disabled={transfering}
          >
            Terminer
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FormsMassiveTransferModal;
