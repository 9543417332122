export const ISTHERA_VARS = {
  email: "client_email",
  name_tech: "technician_name",
  name_client: "client_name",
  lastname_client: "client_lastname",
  combined_clients_name: "combined_clients_name",
  phone: "client_phone",
  date: "date",
};

// ex) Adds a container of type "submenu" that contains a plugin.
export const customContainer = {
  // @Required @Unique
  // plugin name
  name: "custom_container",

  // @Required
  // data display
  display: "container",

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add: function (core, targetElement) {
    // @Required
    // Registering a namespace for caching as a plugin name in the context object
    const context = core.context;
    context.custom_container = {};

    // Generate submenu HTML
    // Always bind "core" when calling a plugin function
    let listDiv = this.setSubmenu(core);

    // You must bind "core" object when registering an event.
    /** add event listeners */
    listDiv
      .querySelector(".se-form-group")
      .addEventListener("click", this.onClick.bind(core));

    // @Required
    // You must add the "submenu" element using the "core.initMenuTarget" method.
    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function (core) {
    const listDiv = core.util.createElement("DIV");
    listDiv.className = "se-menu-container se-submenu se-list-layer";
    listDiv.innerHTML =
      "" +
      '<div class="se-list-inner">' +
      '<div class="se-form-group">' +
      // @Required
      // The "position" style of each element surrounding the button must be "relative".
      // suneditor.css: .sun-editor .se-form-group > div {position:relative;}
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customTechnicianName" style="margin: 0 !important;">' +
      '<i class="fas fa-i-cursor"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Nom techicien.ne</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customClientName" style="margin: 0 !important;">' +
      '<i class="fas fa-i-cursor"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Nom client</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customClientLastName" style="margin: 0 !important;">' +
      '<i class="fas fa-i-cursor"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Prenom client</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customClientFirstNameLastName" style="margin: 0 !important;">' +
      '<i class="fas fa-i-cursor"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Nom-Prenom client</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customClientEmail" style="margin: 0 !important;">' +
      '<i class="fas fa-at"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Courriel client</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "<div>" +
      // @Required
      // Enter the button name of the plug-in or default command in the button's "data-command"
      '<button type="button" class="se-btn se-tooltip" data-command="customClientPhone" style="margin: 0 !important;">' +
      '<i class="fas fa-phone"></i>' +
      '<span class="se-tooltip-inner">' +
      '<span class="se-tooltip-text">Téléphone client</span>' +
      "</span>" +
      "</button>" +
      "</div>" +
      "</div>";

    return listDiv;
  },

  onClick: function (e) {
    e.preventDefault();
    e.stopPropagation();

    let target = e.target;
    let command = target.dataset.command;
    if (!command) return;

    const plugin = this.plugins[command];
    this.actionCall(command, plugin.display, target);
  },
};
