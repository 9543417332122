import React, { useState, useContext } from "react";
import styles from "../../css/IstheraHeader.module.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";

const MENU_OPTIONS = {
  home: "Accueil",
  admin: "Admin",
  dev: "Développeur",
};

const IstheraHeader = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(MENU_OPTIONS.home);

  const handleNavClick = (e) => {
    let buttonValue = e.target.value;

    if (buttonValue === MENU_OPTIONS.home) {
      navigate("/");
    } else if (buttonValue === MENU_OPTIONS.admin) {
      navigate("/admin");
    } else if (buttonValue === MENU_OPTIONS.dev) {
      alert("Not yet implemented");
    }

    setActiveMenu(buttonValue);
    closeDrawer();
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const logout = () => {
    authContext.authContext.logoutUser();
    window.location.reload();
  };

  return (
    <div className={styles.header}>
      <SlIconButton name="list" label="Menu" onClick={openDrawer} />
      <div className={styles.img_container}>
        <img
          src={
            require("../../images/newlogo-toutseul-juste-design.png").default
          }
        />
        <div>
          Isthera <span>Forms</span>
        </div>
      </div>
      <div style={{ height: "60%" }}>
        <SlDivider vertical style={{ "--width": "3px" }} />
      </div>

      <div className={styles.img_container_2}>
        <img
          src={
            require("../../images/VIVA-Principal-Horizontal-Gris.png").default
          }
        />
        <div>Document numérique</div>
      </div>
      <SlDrawer
        label={authContext.authContext.accessToken.username}
        placement="start"
        open={drawerOpen}
        onSlAfterHide={closeDrawer}
      >
        <div className={styles.nav_container}>
          <div className={styles.nav_button_container}>
            <SlButton
              onClick={handleNavClick}
              className={
                activeMenu === MENU_OPTIONS.home
                  ? styles.nav_button_selected
                  : styles.nav_button
              }
              value={MENU_OPTIONS.home}
            >
              <SlIcon slot="prefix" name="house"></SlIcon>
              Accueil
            </SlButton>
          </div>
          {authContext.authContext.accessToken.is_admin ||
          authContext.authContext.accessToken.is_super_user ? (
            <div className={styles.nav_button_container}>
              <SlButton
                onClick={handleNavClick}
                className={
                  activeMenu === MENU_OPTIONS.admin
                    ? styles.nav_button_selected
                    : styles.nav_button
                }
                value={MENU_OPTIONS.admin}
              >
                <SlIcon slot="prefix" name="kanban"></SlIcon>
                Administrateur
              </SlButton>
            </div>
          ) : (
            <></>
          )}
          {authContext.authContext.accessToken.is_super_user ? (
            <div className={styles.nav_button_container}>
              <SlButton
                onClick={handleNavClick}
                className={
                  activeMenu === MENU_OPTIONS.dev
                    ? styles.nav_button_selected
                    : styles.nav_button
                }
                value={MENU_OPTIONS.dev}
              >
                <SlIcon slot="prefix" name="braces"></SlIcon>
                Développeur
              </SlButton>
            </div>
          ) : (
            <></>
          )}
          <div className={styles.nav_button_container}>
            <SlButton
              onClick={logout}
              className={styles.nav_button}
              value={MENU_OPTIONS.home}
            >
              <SlIcon slot="prefix" name="box-arrow-left"></SlIcon>
              Déconnexion
            </SlButton>
          </div>
        </div>
      </SlDrawer>
    </div>
  );
};

export default IstheraHeader;
