import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import styles from "../../css/FormViewer.module.css";
import api_endpoints from "../../api";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import AirDatepicker from "air-datepicker";
import "../../css/AirDatepicker.css";
import "air-datepicker/air-datepicker.css";
import localFr from "air-datepicker/locale/fr";
import SignatureCanvas from "../SignatureCanvas/index";
import SignaturePad from "signature_pad";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { ISTHERA_VARS } from "../EditorContainer";
import GlobalSpinner from "../Spinner/index";
import Fab from "@mui/material/Fab";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const FormViewer = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formContent, setFormContent] = useState(null);
  const [drawings, setDrawings] = useState({});
  const drawingsRef = useRef(drawings);
  const [signatures, setSignatures] = useState({});
  const [showSignatureCanvas, setShowSignatureCanvas] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState("");
  const [archives, setArchives] = useState([]);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [clientDataSaved, setClientDataSaved] = useState(null);

  useEffect(() => {
    fetchForm();
    handleWindowResize();
  }, []);

  useEffect(() => {
    if (formContent) {
      enableFields();
      setTimeout(() => {
        initDrawings();
        fetchUserData();
        alterCheckboxTables();
        fetchArchives();
      }, 0);
    }
  }, [formContent]);

  useEffect(() => {
    insertDrawings();
  }, [clientDataSaved]);

  useEffect(() => {
    changeSignatureButtonColor();
  }, [signatures]);

  useEffect(() => {
    drawingsRef.current = drawings;

    if (Object.keys(drawings).length > 0) {
      resizeDrawings();
    }
  }, [drawings]);

  const handleWindowResize = () => {
    window.onresize = () => {
      resizeDrawings();
    };
  };

  const alterCheckboxTables = () => {
    let contentNode = document.getElementById("content_node");
    let tables = contentNode.getElementsByTagName("table");
    let numCells = 0;
    let count = 0;

    loop1: for (let table of tables) {
      let tbodies = table.getElementsByTagName("tbody");

      loop2: for (let tbody of tbodies) {
        let cells = tbody.getElementsByTagName("td");
        numCells = cells.length;
        count = 0;

        loop3: for (let cell of cells) {
          let isValidCheckboxCell = validCheckboxCell(cell);

          if (!isValidCheckboxCell) {
            break loop3;
          }

          count += 1;
        }
      }

      if (count === numCells) {
        table.classList.add(styles["checkbox_table"]);
      }
    }
  };

  const validCheckboxCell = (cell) => {
    const allowedElements = ["BR", "LABEL", "DIV", "&ZeroWidthSpace;", "#text"];

    if (cell.childNodes[0].nodeName !== "LABEL") {
      for (const node of cell.childNodes) {
        if (node.nodeName === "DIV") {
          for (const innerNode of node.childNodes) {
            if (!allowedElements.includes(innerNode.nodeName)) {
              return false;
            } else if (
              innerNode.nodeName === "LABEL" &&
              innerNode.childNodes[0].type === "checkbox"
            ) {
              return true;
            }
          }
        }
      }
    } else {
      for (const node of cell.childNodes) {
        if (!allowedElements.includes(node.nodeName)) {
          return false;
        } else if (
          node.nodeName === "LABEL" &&
          node.childNodes[0].type === "checkbox"
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const fetchForm = async () => {
    const response = await api_endpoints.get_form(state.form_id);
    setFormContent(JSON.parse(response.data));
    enableFields();
  };

  const fetchUserData = async () => {
    const response = await api_endpoints.get_last_submited_form(
      state.client.numero,
      state.form_id
    );

    if (response.status === 200) {
      let responseData = JSON.parse(response.data.client_data)[0];

      if (responseData) {
        let clientData = JSON.parse(responseData?.fields);
        setClientDataSaved(clientData);
        insertClientData(clientData);
      }
    }
  };

  const initDrawings = () => {
    let contentNode = document.getElementById("content_node");
    let drawings = contentNode.querySelectorAll("img[alt='dessin']");

    let drawingsState = {};

    for (let drawing of drawings) {
      let canvas = document.createElement("canvas");
      canvas.classList.add(styles["drawing_canvas"]);
      canvas.style.width = `${drawing.clientWidth}px`;
      canvas.style.height = `${drawing.clientHeight}px`;
      drawing.parentNode.prepend(canvas);

      let signature_pad = new SignaturePad(canvas, {
        minWidth: 1,
        maxWidth: 2.5,
        penColor: "red",
      });

      drawingsState[drawing.name] = {
        canvas: canvas,
        signature_pad: signature_pad,
      };
    }

    setDrawings(drawingsState);
  };

  const resizeDrawings = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    let pageScale = 1.2;

    for (const [key, value] of Object.entries(drawingsRef.current)) {
      let isEmpty = value.signature_pad.isEmpty();
      let img = value.signature_pad.toDataURL();

      value.canvas.width = value.canvas.offsetWidth * pageScale * ratio;
      value.canvas.height = value.canvas.offsetHeight * pageScale * ratio;
      value.canvas.getContext("2d").scale(ratio, ratio);
      value.signature_pad.clear();

      if (!isEmpty) {
        drawingsRef.current[key].signature_pad.fromDataURL(img);
      }
    }
  };

  const insertClientData = (clientData) => {
    let tempSignatures = {};

    for (const [key, value] of Object.entries(clientData)) {
      if (document.getElementById(key) === null) continue;

      if (
        !key.includes("BUTTON_signature") &&
        !key.includes("INPUT_checkbox") &&
        !key.includes("dessin")
      ) {
        document.getElementById(key).value = value;
        document.getElementById(key).click();
      } else if (key.includes("INPUT_checkbox")) {
        let isChecked = false;

        if (value === "on") {
          isChecked = true;
        }

        document.getElementById(key).checked = isChecked;
      }
      //  else if (key.includes("dessin")) {
      //   drawingsRef.current[key].signature_pad.fromDataURL(value);
      // }
      else {
        tempSignatures[key] = value;
      }
    }

    if (tempSignatures) {
      setSignatures({ ...signatures, ...tempSignatures });
    }
  };

  const insertDrawings = () => {
    if (!clientDataSaved) return;

    for (const [key, value] of Object.entries(clientDataSaved)) {
      if (key.includes("dessin")) {
        drawingsRef.current[key].signature_pad.fromDataURL(value);
      }
    }
  };

  const processFormData = () => {
    let contentNode = document.getElementById("content_node");
    let client = state.client.numero;
    let user = authContext.authContext.accessToken.id;
    let fields = {};
    let userInputs = [].concat(
      contentNode.querySelectorAll("input"),
      contentNode.querySelectorAll("textarea"),
      contentNode.querySelectorAll("canvas")
    );

    let emailField = contentNode.querySelectorAll(
      "input[data-command='client_email']"
    )[0];

    if (emailField) emailField = emailField.value;

    for (let input of userInputs) {
      input.forEach((element) => {
        if (element.value !== "" || element.checked) {
          fields[element.name] = element.value;
        }

        if (element.type === "checkbox" && !element.checked) {
          fields[element.name] = "off";
        }

        if (element.tagName === "CANVAS") {
          let img_node = element.nextSibling;

          if (!drawingsRef.current[img_node.name].signature_pad.isEmpty()) {
            fields[img_node.name] =
              drawingsRef.current[img_node.name].signature_pad.toDataURL();
          }
        }
      });
    }

    for (const [key, value] of Object.entries(signatures)) {
      if (value) {
        fields[key] = value;
      }
    }

    return {
      client: client,
      user: user,
      fields: JSON.stringify(fields),
      form_id: state.form_id,
      client_email: emailField,
    };
  };

  const handleFormSubmit = async () => {
    let processedData = processFormData();
    let response = await api_endpoints.submit_form(
      processedData.fields,
      processedData.user,
      processedData.client,
      processedData.form_id,
      processedData.client_email
    );

    if (response.status === 200) {
      let client = state.client;
      navigate("/", { state: { client: client } });
    }
  };

  const changeSignatureButtonColor = () => {
    let contentNode = document.getElementById("content_node");
    let buttons = contentNode.querySelectorAll("button");

    for (let button of buttons) {
      for (const [key, value] of Object.entries(signatures)) {
        if (key === button.id && value !== "") {
          button.style.backgroundColor = "rgb(46, 204, 64)";
          button.innerText = "Signé";
          let preview = document.getElementById(`${button.id}_preview`);

          if (preview) {
            preview.src = value;
            preview.style.display = "none";
            preview.style.border = "0";
            preview.style.outline = "0";
          }
        } else if (
          key === button.id &&
          value === "" &&
          button.style.backgroundColor === "rgb(46, 204, 64)"
        ) {
          button.style.backgroundColor = "rgb(240, 240, 240)";
          button.innerText = "Signer";
        }
      }
    }
  };

  const handleBackButton = () => {
    let confirmation = confirm(
      "Voulez-vous quitter cette page ? Les changements pas sauvegardés seront perdus."
    );

    if (confirmation) {
      let client = state.client;
      navigate("/", { state: { client: client } });
    } else {
      history.pushState(null, null, window.location.pathname);
    }

    history.pushState(null, null, window.location.pathname);
  };

  const initCalendar = (elementId) => {
    new AirDatepicker(`#${elementId}`, {
      isMobile: true,
      autoClose: true,
      locale: localFr,
      dateFormat(date) {
        let year = date.toLocaleString("fr", { year: "numeric" });
        let month = date.toLocaleString("fr", { month: "2-digit" });
        let day = date.toLocaleString("fr", { day: "2-digit" });
        return `${year}-${month}-${day}`;
      },
    });
  };

  const openSignatureCanvas = (idSignature) => {
    setShowSignatureCanvas(true);
    setSelectedSignature(idSignature);
  };

  const copyAttributes = (target, source) => {
    [...source.attributes].forEach((attr) => {
      target.setAttribute(attr.nodeName, attr.nodeValue);
    });

    return target;
  };

  const archiveForm = async () => {
    let response = await api_endpoints.archive_submited_form(
      state.form_id,
      state.client.numero
    );

    if (response.status === 200) {
      location.reload();
    }
  };

  const fetchArchives = async () => {
    const response = await api_endpoints.get_user_archives(
      state.form_id,
      state.client.numero
    );

    if (response.status === 200) {
      try {
        let data = JSON.parse(response.data?.archives);

        if (data.length > 0) {
          setArchives(data);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const enableFields = () => {
    let contentNode = document.getElementById("content_node");
    let tempSignatures = {};

    let userInputs = [
      contentNode.querySelectorAll("input"),
      contentNode.querySelectorAll("textarea"),
      contentNode.querySelectorAll("button"),
    ];

    for (let input of userInputs) {
      input.forEach((element) => {
        element.disabled = false;
        element.style.resize = "none";

        if (element.tagName === "TEXTAREA" && element.dataset.type === "date") {
          let dateInput = document.createElement("INPUT");
          element.parentNode.style.resize = "none";
          dateInput = copyAttributes(dateInput, element);
          element.style.cursor = "pointer";
          element.replaceWith(dateInput);
          initCalendar(element.id);
        } else if (element.tagName === "BUTTON") {
          element.type = "button";
          element.style.cursor = "pointer";
          tempSignatures[element.id] = "";
          element.onclick = () => openSignatureCanvas(element.id);
          addSignatureImage(element);
        } else if (element.dataset.type === "input") {
          let input = document.createElement("INPUT");
          element.parentNode.style.resize = "none";
          input = copyAttributes(input, element);
          element.replaceWith(input);
          element.style.cursor = "text";
        } else {
          if (element.type !== "checkbox") {
            element.style.setProperty(
              "height",
              `${element.scrollHeight}px`,
              "important"
            );
            element.oninput = (e) => {
              e.target.style.setProperty(
                "height",
                `${e.target.scrollHeight}px`,
                "important"
              );
            };
            element.onclick = (e) => {
              e.target.style.setProperty(
                "height",
                `${e.target.scrollHeight}px`,
                "important"
              );
            };
          }

          element.style.cursor = "auto";
        }

        if (element.dataset.command) {
          onClickIstheraVars(element.id, element.dataset.command);
        }
      });
    }

    setSignatures(tempSignatures);
  };

  const addSignatureImage = (button) => {
    let parentNode = button.parentNode;
    let grandParentNode = parentNode.parentNode;
    let tableTags = [
      "TD",
      "TABLE",
      "TH",
      "TR",
      "CAPTION",
      "COLGROUP",
      "COL",
      "THEAD",
      "TBODY",
      "TFOOT",
    ];

    if (tableTags.includes(grandParentNode.tagName)) return;

    let img = document.createElement("img");
    img.id = `${button.id}_preview`;
    img.style.width = "300px";
    img.style.height = "300px";
    img.style.objectFit = "contain";
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
    img.style.display = "none";
    img.style.marginLeft = "auto";
    img.style.marginRight = "auto";
    parentNode.appendChild(img);
  };

  const onClickIstheraVars = (elementId, command) => {
    let element = document.getElementById(elementId);

    if (!element) return;

    switch (command) {
      case ISTHERA_VARS.email:
        if (element.value === "")
          element.onfocus = () => {
            element.value = state.client?.clients2?.courriel
              ? state.client?.clients2?.courriel
              : "";
          };
        break;
      case ISTHERA_VARS.name_tech:
        if (element.value === "")
          element.onfocus = () => {
            element.value = authContext.authContext.accessToken.username;
          };
        break;
      case ISTHERA_VARS.name_client:
        if (element.value === "")
          element.onfocus = () => {
            element.value = state.client?.clients3?.nom
              ? state.client.clients3.nom
              : "";
          };
        break;
      case ISTHERA_VARS.lastname_client:
        element.onfocus = () => {
          if (element.value === "")
            element.value = state.client?.clients3?.prenom
              ? state.client.clients3.prenom
              : "";
        };
        break;
      case ISTHERA_VARS.combined_clients_name:
        try {
          element.onfocus = () => {
            if (element.value === "")
              element.value =
                state.client?.clients3?.nom +
                " " +
                state.client?.clients3?.prenom;
          };
        } catch (e) {}
        break;
      case ISTHERA_VARS.phone:
        element.onfocus = () => {
          if (element.value === "")
            element.value = state.client?.telephone
              ? state.client.telephone
              : "";
        };
        break;
      case "sequence":
        element.onfocus = () => {
          if (element.value === "")
            element.value = element.dataset.sequenceValue;
        };

        break;
      default:
        break;
    }
  };

  const goDown = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const renderArchive = (submited_form_id) => {
    return (
      <div className={styles.main_container}>
        <div className={styles.wrapper} style={{ pointerEvents: "none" }}>
          <div className={styles.wrapper_inner} id={`${submited_form_id}_node`}>
            {parse(formContent.body)}
          </div>
        </div>
      </div>
    );
  };

  const getArchiveData = (submited_form_id) => {
    for (const archive of archives) {
      if (archive._id.$oid === submited_form_id) return archive;
    }
  };

  const changeArchiveChildrenIDs = (element, submited_form_id) => {
    let childrens = [
      element.querySelectorAll("input"),
      element.querySelectorAll("textarea"),
      element.querySelectorAll("canvas"),
      element.querySelectorAll("button"),
    ];

    for (let children of childrens) {
      for (let node of children) {
        node.id = `${node.id}_${submited_form_id}`;
      }
    }
  };

  const insertArchiveData = (submited_form_id) => {
    const element = document.getElementById(`${submited_form_id}_node`);
    const archiveData = getArchiveData(submited_form_id);
    let clientData = {};

    try {
      clientData = JSON.parse(archiveData.fields);
    } catch (e) {
      console.error(e);
    }

    if (!clientData) return;
    changeArchiveChildrenIDs(element, submited_form_id);

    for (const [key, value] of Object.entries(clientData)) {
      if (
        !key.includes("BUTTON_signature") &&
        !key.includes("INPUT_checkbox") &&
        !key.includes("dessin")
      ) {
        document.getElementById(`${key}_${submited_form_id}`).value = value;
      } else if (key.includes("INPUT_checkbox")) {
        let isChecked = false;

        if (value === "on") {
          isChecked = true;
        }

        document.getElementById(`${key}_${submited_form_id}`).checked =
          isChecked;
      } else if (key.includes("dessin")) {
        drawingsRef.current[key].signature_pad.fromDataURL(value);
      } else if (key.includes("BUTTON_signature")) {
        let imageElement = null;
        addSignatureImage(
          document.getElementById(`${key}_${submited_form_id}`)
        );
        document.getElementById(`${key}_${submited_form_id}`).style.display =
          "none";
        imageElement = document.getElementById(
          `${key}_${submited_form_id}_preview`
        );

        imageElement.parentNode.style.height = "150px";
        imageElement.style.display = "block";
        imageElement.style.border = "0";
        imageElement.style.outline = "0";
        imageElement.src = value;
      }
    }

    forceUpdate();
  };

  return (
    <GlobalSpinner>
      <Dialog
        onClose={() => setArchiveDialogOpen(false)}
        open={archiveDialogOpen}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>Archives du client</DialogTitle>
        {archives?.map((archive) => (
          <Accordion key={archive._id.$oid}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#3a77ff", color: "white" }}
              onClick={() => insertArchiveData(archive._id.$oid)}
            >
              Archive - {archive.created_at}
            </AccordionSummary>
            <AccordionDetails>
              {renderArchive(archive._id.$oid)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Dialog>
      <div className={styles.main_container}>
        <SignatureCanvas
          setSignatures={setSignatures}
          signatures={signatures}
          show={showSignatureCanvas}
          setShow={setShowSignatureCanvas}
          selectedSignature={selectedSignature}
        />
        <div className={styles.header}>
          <div>
            <span>{formContent?.title}</span>
          </div>

          <div className={styles.header_buttons}>
            <button onClick={handleBackButton}>
              <i class="fas fa-arrow-left"></i>
              Retour
            </button>
            <button onClick={handleFormSubmit}>
              <i class="far fa-save"></i>
              Soumettre
            </button>
            <button onClick={print}>
              <i class="fas fa-print"></i>
              Imprimer
            </button>
            <button onClick={archiveForm}>
              <i class="fas fa-archive"></i>
              Archiver
            </button>
          </div>
        </div>
        <div className={styles.wrapper} id={"content_node_wrapper"}>
          <div className={styles.wrapper_inner} id={"content_node"}>
            {formContent ? parse(formContent.body) : <></>}
          </div>
        </div>
        <div
          style={{
            position: "sticky",
            width: "max-content",
            bottom: 30,
            marginLeft: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {archives.length > 0 ? (
            <Fab
              color="primary"
              aria-label="Ouvrir archives"
              sx={{ mb: 2, displayPrint: "none" }}
              onClick={() => setArchiveDialogOpen(true)}
            >
              <i class="fas fa-archive" style={{ fontSize: 25 }}></i>
            </Fab>
          ) : (
            <></>
          )}
          <Fab
            color="primary"
            aria-label="Aller en bas"
            onClick={goDown}
            sx={{ displayPrint: "none" }}
          >
            <ArrowDownwardIcon />
          </Fab>
        </div>
      </div>
    </GlobalSpinner>
  );
};

export default FormViewer;
