import React, { useEffect, useState } from "react";
import styles from "../../css/UserSelection.module.css";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import api_endpoints from "../../api/index";
import axios from "axios";

const UserSelection = ({ setselectedClient }) => {
  const [users, setUsers] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    let clientid_vivaold = localStorage.getItem("clientid_vivaold");

    if (clientid_vivaold) {
      handleFormSelect({ numero: clientid_vivaold });
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function fetchUsers() {
      try {
        let response = await api_endpoints.get_clients(
          controller.signal,
          filterValue
        );

        if (response.status === 200 && response.data.clients.length > 0) {
          setUsers(response.data.clients);
        }
      } catch (e) {}
    }

    if (filterValue) {
      fetchUsers();
    }

    return () => {
      controller.abort();
    };
  }, [filterValue]);

  const handleFormSelect = async (user) => {
    let response = await api_endpoints.get_client_data(user.numero);
    if (response.status === 200) {
      setselectedClient(response.data.clients);
      localStorage.removeItem("clientid_vivaold");
    }
  };

  return (
    <div className={styles.main_container}>
      <div>
        <h2>Sélectionner un client</h2>
      </div>
      <SlDivider />
      <div className={styles.filter_container}>
        <SlInput
          placeholder="Filtre"
          value={filterValue}
          onInput={(e) => setFilterValue(e.target.value)}
        />
      </div>
      <div className={styles.user_list_container}>
        {users.length === 0 ? (
          <div
            style={{
              fontSize: 20,
              marginTop: 30,
              textAlign: "center",
            }}
          >
            Aucun utilisateur trouvé. Veuillez commencer par chercher un numéro
            de client, un nom, une adresse, une ville ou un numéro de téléphone.
          </div>
        ) : (
          users.map((user, index) => (
            <div
              className={styles.user_container}
              key={user.numero}
              onClick={() => handleFormSelect(user)}
            >
              <div className={"flex flex-1 flex-col"}>
                <div style={{ fontSize: 22 }}>{user.numero}</div>
                <div style={{ fontSize: 18, color: "gray" }}>
                  {user.nom ? user.nom : "- -"}
                </div>
                <div style={{ fontSize: 18, color: "gray" }}>
                  {user.telephone ? user.telephone : "- -"}
                </div>
              </div>
              <div className={"flex flex-1 w-full flex-col"}>
                <div style={{ fontSize: 18, color: "gray" }}>
                  {user.ville ? user.ville : "- -"}
                </div>
                <div style={{ fontSize: 18, color: "gray" }}>
                  {user.adresse ? user.adresse : "- -"}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserSelection;
