import React, { useEffect, useState, useContext } from "react";
import styles from "../../css/HomeScreen.module.css";
import { useNavigate, useLocation, Form, useFetcher } from "react-router-dom";
import api_endpoints from "../../api";
import ReactModal from "../Modal";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";
import SlDropdown from "@shoelace-style/shoelace/dist/react/dropdown";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import UserSelection from "../UserSelection/index";
import GlobalSpinner from "../Spinner";
import { BASE_URL } from "../../constants";
import SlCheckbox from "@shoelace-style/shoelace/dist/react/checkbox";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import FormsTransferModal from "../FormsTransferModal/FormsTransferModal";
import FormsMassiveTransferModal from "../FormsMassTransferModal/FormsMassTransferModal";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from "axios";

const VALID_PIN = "%VIVA2023%";

const PAGES = {
  mainPage: "mainPage",
  createFormPage: "createFormPage",
  createDirPage: "createDirPage",
  modifyDirForm: "modifyDirForm",
  modifyDirName: "modifyDirName",
  modalModifyDirImg: "modalModifyDirImg",
  modalModifyFormImg: "modalModifyFormImg",
};

const VIEW_OPTIONS = {
  users: "users",
  directories: "directories",
  forms: "form",
  fichesConseils: "fichesconseils",
  formsListPrint: "formslistprint",
};

var selectedForm = null;

var lockType = 0;

const HomeScreen = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedClient, setselectedClient] = useState(
    state?.client ? state.client : {}
  );
  const [forms, setForms] = useState([]);
  const [dialogEmailOpen, setDialogEmailOpen] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [searchBarText, setSearchBartext] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPage, setModalPage] = useState(PAGES.mainPage);
  const [directories, setDirectories] = useState(null);
  const [dirNameInput, setDirNameInput] = useState("");
  const [selectedDirectory, setSelectedDirectory] = useState("");
  const [selectedNewFormDir, setSelectedNewFormDir] = useState("");
  const [clickedDirectory, setClickedDirectory] = useState({});
  const [fichesConseils, setFichesConseils] = useState([]);
  const [selectedFichesConseils, setSelectedFichesConseils] = useState([]);
  const [selectedViewOption, setSelectedViewOption] = useState(
    VIEW_OPTIONS.directories
  );
  const [selectedFormModify, setSelectedFormModify] = useState({});
  const [newDirName, setNewDirName] = useState("");
  const [imageDir, setImageDir] = useState(null);
  const [imageDirPreview, setImageDirPreview] = useState(null);
  const [imageForm, setImageForm] = useState(null);
  const [imageFormPreview, setImageFormPreview] = useState(null);
  const [imgModifyDir, setImgModifyDir] = useState(null);
  const [imgModifyForm, setImgModifyForm] = useState(null);
  const [formsListPrint, setFormsListPrint] = useState({});
  const [modalTransferOpen, setModalTransferOpen] = useState(false);
  const [modalMassiveTransferOpen, setModalMassiveTransferOpen] =
    useState(false);
  const [filledForms, setFilledForms] = useState([]);
  const [filledFormsDirectories, setFilledFormsDirectories] = useState([]);
  const [
    confirmationDialogLockStatusOpen,
    setConfirmationDialogLockStatusOpen,
  ] = useState(false);
  const [
    confirmationDialogLockStatusInput,
    setConfirmationDialogLockStatusInput,
  ] = useState("");

  useEffect(() => {
    fetchClient();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setClientEmail(selectedClient?.clients2?.courriel);
      handleViewOptionSelect(VIEW_OPTIONS.directories);
      // fetchForms();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedViewOption === VIEW_OPTIONS.formsListPrint) mapFormsListPrint();
  }, [selectedViewOption]);

  // useEffect(() => {
  //   mapFormsListPrint();
  // }, [forms, directories]);

  const fetchClient = async () => {
    try {
      let response = await api_endpoints.get_client_data(state.client.numero);
      if (response.status === 200) {
        setselectedClient(response.data.clients);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchForms = async () => {
    setLoading(true);
    const response = await api_endpoints.get_all_forms(selectedClient.numero);

    try {
      if (response.status === 200) {
        const data = JSON.parse(response.data);
        setForms(data.forms);
        // mapFormsListPrint(data.forms);
        setFilledForms(data.filled_forms);
        // fetchDirectories();
      }
    } catch (e) {}
    setLoading(false);
  };

  const mapFormsListPrint = async () => {
    let data = {};

    let forms = await api_endpoints.get_all_forms(selectedClient.numero);
    let directories = await api_endpoints.get_all_directories(
      selectedClient.numero
    );

    if (!forms.data || !directories.data) return;

    forms = JSON.parse(forms.data).forms;
    directories = JSON.parse(directories.data).directories;

    if (
      forms?.length <= 0 ||
      directories?.length <= 0 ||
      !forms ||
      !directories
    )
      return;

    for (let directory of directories) {
      if (!data[directory.directory] && !directory.deleted && directory)
        data[directory.directory] = [];
    }

    for (let form of forms) {
      if (!form.deleted && data[form.directory] !== undefined)
        data[form.directory].push(form.title);
    }

    setFormsListPrint(data);
  };

  const fetchDirectories = async () => {
    try {
      const response = await api_endpoints.get_all_directories(
        selectedClient.numero
      );
      let parsedData = JSON.parse(response.data);

      if (parsedData.directories.length > 0) {
        mapDirectories(parsedData.directories);
        setFilledFormsDirectories(parsedData.filled_forms_directories);
      }
    } catch (e) {}
    setLoading(false);
  };

  const mapDirectories = (directories) => {
    let directoriesArray = [];

    directoriesArray.push({
      value: "",
      label: "Aucun répertoire",
      id: 0,
      created_at: "",
      img_url: "",
      disabled: false,
    });

    for (let directory of directories) {
      if (
        directory.directory === "Z-Laboratoire" &&
        !authContext.authContext.accessToken.is_admin
      )
        continue;

      directoriesArray.push({
        value: directory.directory,
        label: directory.directory,
        created_at: directory.created_at,
        id: directory._id.$oid,
        img_url: directory.img_url,
        disabled: directory.disabled,
      });
    }

    setDirectories(directoriesArray);
  };

  const filterAlphabeticalOrder = (param) => {
    let tempArrayForms = [...forms];
    let tempArrayDir = [...directories];

    if (param === "alphabetical") {
      tempArrayForms.sort((a, b) => {
        let a2 = a.title;
        let b2 = b.title;

        if (a2 && a2.length > 0) {
          a2 = a2.trim();
        }
        if (b2 && b2.length > 0) {
          b2 = b2.trim();
        }

        if (a2 < b2) return -1;

        if (a2 > b2) return 1;

        return 0;
      });
      tempArrayDir.sort((a, b) => {
        let a2 = a.value;
        let b2 = b.value;

        if (a2 && a2.length > 0) {
          a2 = a2.trim();
        }
        if (b2 && b2.length > 0) {
          b2 = b2.trim();
        }

        if (a2 < b2) return -1;

        if (a2 > b2) return 1;

        return 0;
      });
    } else if (param === "numerical") {
      tempArrayForms.sort((a, b) => {
        return Date.parse(b.created_at) - Date.parse(a.created_at);
      });

      tempArrayDir.sort((a, b) => {
        return Date.parse(b.created_at) - Date.parse(a.created_at);
      });
    }

    if (tempArrayForms && tempArrayDir) {
      setForms(tempArrayForms);
      setDirectories(tempArrayDir);
    }
  };

  const handleFormSelect = (e) => {
    let form_id = e.currentTarget.dataset.formid;
    navigate("/form", { state: { form_id: form_id, client: selectedClient } });
  };

  const handleNewFormClick = () => {
    openModal();
  };

  const handleModifyForm = (e, form_data) => {
    e.stopPropagation();
    navigate("/new_form", {
      state: {
        form_data: { ...form_data },
        client: selectedClient,
      },
    });
  };

  const handleMoveFormDir = async () => {
    let response = await api_endpoints.change_form_dir(
      selectedFormModify._id.$oid,
      selectedFormModify.title,
      selectedNewFormDir
    );

    if (response.status === 200) {
      closeModal();
      window.location.reload();
    }
  };

  const handleDeleteForm = async (e, form_data) => {
    e.stopPropagation();

    if (confirm("Êtes-vous sûr de vouloir effacer le formulaire ?")) {
      let response = await api_endpoints.delete_form(form_data._id.$oid);

      if (response.status === 200) {
        window.location.reload();
      }
    }
  };

  const handleDeleteDir = async (directory) => {
    if (confirm("Êtes-vous sûr de vouloir détruire le répertoire ?")) {
      let response = await api_endpoints.delete_dir(directory);

      if (response.status === 200) {
        window.location.reload();
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchBartext(e.target.value);
  };

  const clearSearchBar = () => {
    setSearchBartext("");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalPage(PAGES.mainPage);
    setSelectedDirectory("");
  };

  const openModal = () => {
    fetchDirectories();
    setModalIsOpen(true);
  };

  const handleCreateForm = () => {
    navigate("/new_form", {
      state: {
        directory: selectedDirectory,
        client: selectedClient,
        imageForm: imageForm,
      },
    });
  };

  const handleDirNameInputchange = (e) => {
    setDirNameInput(e.target.value);
  };

  const handleCreateDir = async () => {
    let fd = new FormData();

    if (imageDir)
      fd.append(
        "dir_form_data",
        imageDir,
        `${dirNameInput}|||${imageDir.name}`
      );
    else fd.append("dir_form_data", dirNameInput);

    let response = await api_endpoints.create_directory(fd);

    if (response.status === 200) {
      setImageDirPreview(null);
      setImgModifyDir(null);
      closeModal();
      fetchDirectories();
    }
  };

  const handleDirSelected = (e) => {
    setSelectedDirectory(e.value);
  };

  const handleNewFormDirSelected = (e) => {
    setSelectedNewFormDir(e.value);
  };

  const handleDirectorySelect = async (directory) => {
    let response = await api_endpoints.get_forms_from_dir(
      directory.value,
      selectedClient.numero
    );
    const data = JSON.parse(response.data);
    setSelectedViewOption(VIEW_OPTIONS.forms);
    setClickedDirectory(directory);
    setSelectedDirectory(directory.value);
    setFilledForms(data.filled_forms);
    setForms(data.forms);
  };

  const handleViewOptionSelect = async (viewOption) => {
    if (viewOption === VIEW_OPTIONS.directories) {
      await fetchDirectories();
      setSelectedViewOption(VIEW_OPTIONS.directories);
    } else if (viewOption === VIEW_OPTIONS.forms) {
      await fetchForms();
      setSelectedViewOption(VIEW_OPTIONS.forms);
    } else if (viewOption === VIEW_OPTIONS.fichesConseils) {
      await fetchFichesConseils();
      setSelectedViewOption(VIEW_OPTIONS.fichesConseils);
    } else if (viewOption === VIEW_OPTIONS.formsListPrint) {
      setSelectedViewOption(VIEW_OPTIONS.formsListPrint);
    }
    setClickedDirectory({});
  };

  const fetchFichesConseils = async () => {
    let response = await api_endpoints.get_list_fiches_conseils();

    if (response.status === 200) {
      setFichesConseils(response.data);
    }
  };

  const handleDirRename = async () => {
    let response = await api_endpoints.rename_dir(newDirName, clickedDirectory);

    if (response.status === 200) {
      window.location.reload();
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageDirPreview(URL.createObjectURL(event.target.files[0]));
      setImageDir(event.target.files[0]);
    }
  };

  const onImageChangeForm = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFormPreview(URL.createObjectURL(event.target.files[0]));
      setImageForm(event.target.files[0]);
    }
  };

  const onImageModifyDirChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageDirPreview(URL.createObjectURL(event.target.files[0]));
      setImgModifyDir(event.target.files[0]);
    }
  };

  const onFicheConseilChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const ficheConseil = event.target.files[0];
      let fd = new FormData();

      fd.append("fiche_conseil", ficheConseil, ficheConseil.name);

      let response = await api_endpoints.upload_fiche_conseil(fd);

      if (response.status === 200) {
        fetchFichesConseils();
      }
    }
  };

  const handleModifyDirImg = async () => {
    let fd = new FormData();

    if (imgModifyDir) {
      fd.append("image", imgModifyDir, imgModifyDir.name);
    }

    fd.append("dir_id", clickedDirectory.id);

    let response = await api_endpoints.modify_image_dir(fd);

    if (response.status === 200) {
      setImageDirPreview(null);
      setImgModifyDir(null);
      closeModal();
      fetchDirectories();
    }
  };

  const ficheConseilExists = () => {};

  const onImageModifyFormChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFormPreview(URL.createObjectURL(event.target.files[0]));
      setImgModifyForm(event.target.files[0]);
    }
  };

  const handleModifyFormImg = async () => {
    let fd = new FormData();

    if (imgModifyForm) {
      fd.append("image", imgModifyForm, imgModifyForm.name);
    }

    fd.append("form_name", selectedFormModify._id.$oid);

    let response = await api_endpoints.modify_image_form(fd);

    if (response.status === 200) {
      setImageFormPreview(null);
      setImgModifyForm(null);
      closeModal();
      fetchForms();
    }
  };

  const setFicheConseilChecked = (e) => {
    if (e.target.checked) {
      setSelectedFichesConseils([...selectedFichesConseils, e.target.value]);
    } else {
      let currentArray = [...selectedFichesConseils];
      let index = currentArray.indexOf(e.target.value);
      currentArray.splice(index, 1);
      setSelectedFichesConseils([...currentArray]);
    }
  };

  const handleDeleteFiche = async () => {
    if (selectedFichesConseils.length > 0) {
      let response = await api_endpoints.remove_fiche_conseil(
        selectedFichesConseils
      );

      if (response.status === 200) {
        fetchFichesConseils();
      }
    }
  };

  const openFichesConseils = () => {
    for (const fiche of selectedFichesConseils) {
      window
        .open(`${BASE_URL}/media/fiches_conseils/${fiche}`, "_blank")
        .focus();
    }
  };

  const handleClientEmailChange = (e) => {
    setClientEmail(e.target.value);
    setValidEmail(true);
  };

  const email_ficheconseils = async () => {
    try {
      let response = await api_endpoints.email_ficheconseils(
        selectedFichesConseils,
        clientEmail,
        selectedClient.numero
      );
      if (response.status === 200) {
        setDialogEmailOpen(false);
        setValidEmail(true);
        fetchClient();
      }
    } catch (e) {
      console.error(e);
      setValidEmail(false);
    }
  };

  const isFilledByClient = (formId) => {
    for (const filledForm of filledForms) {
      if (formId === filledForm._id) return true;
    }
    return false;
  };

  const setFormLockStatus = async () => {
    try {
      let status = selectedForm.modifiable;

      if (status === undefined) status = true;

      const response = await api_endpoints.set_lock_status_form(
        selectedForm._id.$oid,
        !status
      );

      if (response.status === 200) {
        handleDirectorySelect({ value: selectedForm.directory });
      }
    } catch (e) {
      console.error(e);
    }

    selectedForm = null;
  };

  const validateConfirmationAdmin = async () => {
    if (VALID_PIN === confirmationDialogLockStatusInput) {
      if (lockType === 1) await setFormLockStatus();
      else if (lockType === 2) await handleDeleteFiche();

      setConfirmationDialogLockStatusInput("");
      setConfirmationDialogLockStatusOpen(false);
    }
  };

  const createCopyForm = async (e, form) => {
    e.stopPropagation();
    try {
      const response = await api_endpoints.create_copy_form(
        JSON.stringify(form)
      );

      if (response.status === 200) {
        handleDirectorySelect({ value: form.directory });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const modalCreateFormPage = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="fas fa-file"></i>
            <span>Nouveau formulaire</span>
          </div>
          <div className={styles.modal_create_body}>
            <div className={styles.modal_create_body_left}>
              <span>Affecter un ou aucun répertoire:</span>
              <div>
                <Select
                  options={directories}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  defaultValue={
                    clickedDirectory
                      ? {
                          value: clickedDirectory.value,
                          label: clickedDirectory.label,
                        }
                      : { value: "", label: "Aucun répertoire" }
                  }
                  noOptionsMessage={() => "Il n'y a aucun répertoire"}
                  onChange={handleDirSelected}
                />
              </div>
              <div>
                <button
                  style={{ display: "block", width: "200px", height: "30px" }}
                  onClick={() =>
                    document.getElementById("selectImageDir").click()
                  }
                >
                  Choisir une image
                </button>
                <input
                  onChange={onImageChangeForm}
                  id="selectImageDir"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className={styles.modal_create_body_right}>
              <div className={styles.form_preview}>
                <div className={styles.form_preview_image}>
                  {imageFormPreview ? (
                    <img alt="preview image" src={imageFormPreview} />
                  ) : (
                    <i class="fas fa-folder"></i>
                  )}
                </div>
                <div className={styles.form_preview_title}>
                  <div className={styles.form_preview_inner_title}>
                    <i class="fas fa-file"></i>
                    <span>Titre</span>
                  </div>
                  <div className={styles.form_options_menu}>
                    <div className={styles.form_options_menu_inner}>
                      <span>Crée le AAAA-MM-DD</span>
                      <span>{selectedDirectory}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button style={{ backgroundColor: "#FF4136" }} onClick={closeModal}>
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#3a77ff" }}
              onClick={handleCreateForm}
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modalCreateDirPage = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="fas fa-folder"></i>
            <span>Nouveau répertoire</span>
          </div>
          <div className={styles.modal_create_body}>
            <div className={styles.modal_create_body_left}>
              <span>Nom du répertoire:</span>
              <div>
                <input onChange={handleDirNameInputchange}></input>
              </div>
              <div>
                <button
                  style={{ display: "block", width: "200px", height: "30px" }}
                  onClick={() =>
                    document.getElementById("selectImageDir").click()
                  }
                >
                  Choisir une image
                </button>
                <input
                  onChange={onImageChange}
                  id="selectImageDir"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className={styles.modal_create_body_right}>
              <div className={styles.form_preview}>
                <div className={styles.form_preview_image}>
                  {imageDirPreview ? (
                    <img alt="preview image" src={imageDirPreview} />
                  ) : (
                    <i class="fas fa-folder"></i>
                  )}
                </div>
                <div className={styles.form_preview_title}>
                  <div className={styles.form_preview_inner_title}>
                    <span>
                      {dirNameInput.length === 0
                        ? "Nom du répertoire"
                        : dirNameInput}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button
              style={{ backgroundColor: "#FF4136" }}
              onClick={() => {
                setDirNameInput("");
                setImageDir(null);
                setImageDirPreview(null);
                closeModal();
              }}
            >
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#3a77ff" }}
              onClick={handleCreateDir}
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modalMainPage = () => {
    return (
      <div className={styles.modal_main_page_container}>
        <div className={styles.modal_main_page_inner}>
          <div>
            <div className={styles.modal_main_page_icons}>
              <i class="fas fa-file"></i>
            </div>
            <div
              onClick={() => {
                setModalPage(PAGES.createFormPage);
              }}
            >
              <span>Créer un formulaire</span>
              <span>
                Créer un formulaire qui sera rempli, par la suite, par des
                clients.
              </span>
            </div>
          </div>
          <div>
            <div className={styles.modal_main_page_icons}>
              <i class="fas fa-folder"></i>
            </div>
            <div
              onClick={() => {
                setModalPage(PAGES.createDirPage);
              }}
            >
              <span>Créer un répertoire</span>
              <span>
                Créer un répertoire qui permettra d'organiser vos formulaires.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modalModifyFormDir = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="fas fa-people-carry"></i>
            <span>Déplacer le formulaire de répertoire</span>
          </div>
          <div className={styles.modal_create_body}>
            <span>
              Changer le répertoire du formulaire pour un des répertoires
              suivants:
            </span>
            <div>
              <Select
                options={directories}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                defaultValue={{
                  value: directories[0].value,
                  label: directories[0].label,
                }}
                noOptionsMessage={() => "Il n'y a aucun répertoire"}
                onChange={handleNewFormDirSelected}
              />
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button style={{ backgroundColor: "#FF4136" }} onClick={closeModal}>
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#3a77ff" }}
              onClick={handleMoveFormDir}
            >
              Déplacer
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modalModifyDirName = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="fas fa-i-cursor"></i>
            <span>Renommer le répertoire</span>
          </div>
          <div className={styles.modal_create_body}>
            <span>
              Affecter un nouveau nom pour le répertoire{" "}
              <i>{clickedDirectory.value}</i>
            </span>
            <div>
              <SlInput
                placeholder="Nouveau nom"
                value={newDirName}
                onInput={(e) => {
                  setNewDirName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button style={{ backgroundColor: "#FF4136" }} onClick={closeModal}>
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#3a77ff", width: "100px" }}
              onClick={handleDirRename}
            >
              Renommer
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modalModifyDirImg = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="far fa-image"></i>
            <span>Modifier l'image du répertoire</span>
          </div>
          <div className={styles.modal_create_body}>
            <span>
              Changer l'image du répertoire présenté dans la liste d'accueil
            </span>
            <div>
              <button
                style={{ display: "block", width: "200px", height: "30px" }}
                onClick={() =>
                  document.getElementById("selectModifyImageDir").click()
                }
              >
                Choisir une image
              </button>
              <input
                onChange={onImageModifyDirChange}
                id="selectModifyImageDir"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
              />
            </div>
            <div className={styles.modal_create_body_right}>
              <div className={styles.form_preview}>
                <div className={styles.form_preview_image}>
                  {imageDirPreview ? (
                    <img alt="preview image" src={imageDirPreview} />
                  ) : clickedDirectory.img_url ? (
                    <img
                      src={`${BASE_URL}/media/${clickedDirectory.img_url}`}
                    />
                  ) : (
                    <i class="fas fa-folder"></i>
                  )}
                </div>
                <div className={styles.form_preview_title}>
                  <div className={styles.form_preview_inner_title}>
                    <span>
                      {!clickedDirectory
                        ? "Nom du répertoire"
                        : clickedDirectory.value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button style={{ backgroundColor: "#FF4136" }} onClick={closeModal}>
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#FF4136", width: 150 }}
              onClick={handleModifyDirImg}
            >
              Effacer image
            </button>
            <button
              style={{ backgroundColor: "#3a77ff" }}
              onClick={handleModifyDirImg}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modalModifyFormImg = () => {
    return (
      <div className={styles.modal_create_main_container}>
        <div className={styles.modal_create_inner}>
          <div className={styles.modal_create_page_header}>
            <i class="far fa-image"></i>
            <span>Modifier l'image du formulaire</span>
          </div>
          <div className={styles.modal_create_body}>
            <span>
              Changer l'image du formulaire présenté dans la liste d'accueil
            </span>
            <div>
              <button
                style={{ display: "block", width: "200px", height: "30px" }}
                onClick={() =>
                  document.getElementById("selectModifyImageDir").click()
                }
              >
                Choisir une image
              </button>
              <input
                onChange={onImageModifyFormChange}
                id="selectModifyImageDir"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
              />
            </div>
            <div className={styles.modal_create_body_right}>
              <div className={styles.form_preview}>
                <div className={styles.form_preview_image}>
                  {imageFormPreview ? (
                    <img alt="preview image" src={imageFormPreview} />
                  ) : selectedFormModify.img_url ? (
                    <img
                      src={`${BASE_URL}/media/${selectedFormModify.img_url}`}
                    />
                  ) : (
                    <i class="fas fa-folder"></i>
                  )}
                </div>
                <div className={styles.form_preview_title}>
                  <div className={styles.form_preview_inner_title}>
                    <span>
                      {!selectedFormModify
                        ? "Nom du répertoire"
                        : selectedFormModify.title}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modal_create_footer}>
            <button style={{ backgroundColor: "#FF4136" }} onClick={closeModal}>
              Annuler
            </button>
            <button
              style={{ backgroundColor: "#FF4136", width: 150 }}
              onClick={handleModifyFormImg}
            >
              Effacer image
            </button>
            <button
              style={{ backgroundColor: "#3a77ff" }}
              onClick={handleModifyFormImg}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalSpinner>
      <Dialog
        open={confirmationDialogLockStatusOpen}
        onClose={() => {
          setConfirmationDialogLockStatusOpen(false);
          setConfirmationDialogLockStatusInput("");
        }}
        fullWidth
        maxSize="lg"
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <div className="pt-4">
            <TextField
              placeholder="Code administrateur"
              label="Code administrateur"
              fullWidth
              value={confirmationDialogLockStatusInput}
              onInput={(e) =>
                setConfirmationDialogLockStatusInput(e.target.value)
              }
              type="password"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setConfirmationDialogLockStatusOpen(false);
              setConfirmationDialogLockStatusInput("");
            }}
          >
            Annuler
          </Button>
          <Button variant="contained" onClick={validateConfirmationAdmin}>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {Object.keys(selectedClient).length === 0 ? (
        <UserSelection setselectedClient={setselectedClient} />
      ) : loading ? (
        <div className="flex flex-1 justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <FormsTransferModal
            open={modalTransferOpen}
            handleClose={setModalTransferOpen}
            client={selectedClient}
          />
          <FormsMassiveTransferModal
            open={modalMassiveTransferOpen}
            handleClose={setModalMassiveTransferOpen}
          />
          <SlDialog
            label="Envoyer des fiches conseils au client par courriel"
            open={dialogEmailOpen}
            onSlAfterHide={() => setDialogEmailOpen(false)}
          >
            <SlInput
              label="Courriel du client"
              placeholder="Courriel du client"
              value={clientEmail}
              onSlInput={handleClientEmailChange}
            />
            <span style={{ color: "red" }}>
              {validEmail
                ? ""
                : "Envoie du courriel échoué. Veuillez verifier le courriel."}
            </span>
            <SlButton
              slot="footer"
              variant="primary"
              onClick={() => email_ficheconseils()}
            >
              Envoyer
            </SlButton>
          </SlDialog>
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentStyle={
              modalPage === PAGES.mainPage
                ? { height: "200px", width: "600px" }
                : modalPage === PAGES.createFormPage
                ? { height: 700, width: 700 }
                : modalPage === PAGES.createDirPage
                ? { height: 700, width: 700 }
                : modalPage === PAGES.modifyDirForm
                ? { height: "400px", width: "600px" }
                : modalPage === PAGES.modifyDirName
                ? { height: "300px", width: "600px" }
                : modalPage === PAGES.modalModifyDirImg ||
                  modalPage === PAGES.modalModifyFormImg
                ? { height: 700, width: 700 }
                : {}
            }
          >
            {modalPage === PAGES.mainPage
              ? modalMainPage()
              : modalPage === PAGES.createFormPage
              ? modalCreateFormPage()
              : modalPage === PAGES.createDirPage
              ? modalCreateDirPage()
              : modalPage === PAGES.modifyDirForm
              ? modalModifyFormDir()
              : modalPage === PAGES.modifyDirName
              ? modalModifyDirName()
              : modalPage === PAGES.modalModifyDirImg
              ? modalModifyDirImg()
              : modalPage === PAGES.modalModifyFormImg
              ? modalModifyFormImg()
              : {}}
          </ReactModal>
          <div className={styles.main_container}>
            <div className={styles.view_options_container}>
              <div className={styles.view_options_inner}>
                <div style={{ marginBottom: 15, fontSize: 18 }}>
                  Technicien.ne:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {authContext.authContext.accessToken.username}
                  </span>
                </div>
                <div className={styles.view_options_inner_buttons}>
                  <button
                    className={
                      selectedViewOption === VIEW_OPTIONS.directories
                        ? styles.selected_view_option_button
                        : ""
                    }
                    onClick={() => {
                      handleViewOptionSelect(VIEW_OPTIONS.directories);
                    }}
                  >
                    Répertoires
                  </button>
                  <button
                    className={
                      selectedViewOption === VIEW_OPTIONS.forms
                        ? styles.selected_view_option_button
                        : ""
                    }
                    onClick={() => {
                      handleViewOptionSelect(VIEW_OPTIONS.forms);
                    }}
                  >
                    Formulaires
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        const response =
                          await api_endpoints.getEncryptedUsername(
                            authContext.authContext.accessToken.username
                          );
                        window.open(
                          `https://cliniqueviva.dyndns.biz:1337/redirect/${response.data.username}/${selectedClient.numero}`,
                          // `http://localhost:8080/redirect/${response.data.username}/${selectedClient.numero}`,
                          "_blank"
                        );
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    Anciens formulaires
                  </button>
                  <SlDropdown style={{ marginLeft: 10, marginRight: 10 }}>
                    <SlIconButton
                      slot="trigger"
                      name="gear"
                      label="Options"
                      style={{ fontSize: 30 }}
                    />
                    <SlMenu>
                      <SlMenuItem
                        onClick={() => {
                          filterAlphabeticalOrder("alphabetical");
                        }}
                      >
                        Trier par ordre alphabétique
                        <SlIcon
                          slot="prefix"
                          name="alphabet-uppercase"
                        ></SlIcon>
                      </SlMenuItem>
                      <SlMenuItem
                        onClick={() => {
                          filterAlphabeticalOrder("numerical");
                        }}
                      >
                        Trier par le plus récent
                        <SlIcon slot="prefix" name="calendar"></SlIcon>
                      </SlMenuItem>
                      <SlMenuItem
                        onClick={() => {
                          handleViewOptionSelect(VIEW_OPTIONS.formsListPrint);
                        }}
                      >
                        Liste de formulaires à imprimer
                        <SlIcon slot="prefix" name="list-ul"></SlIcon>
                      </SlMenuItem>
                      <SlMenuItem
                        onClick={() => {
                          setModalTransferOpen(true);
                        }}
                      >
                        Transférer les données du client
                        <SlIcon slot="prefix" name="arrow-left-right"></SlIcon>
                      </SlMenuItem>
                      <SlMenuItem
                        onClick={() => {
                          if (
                            confirm(
                              "Êtes-vous sur de vouloir faire un transfert massif ? Cela va écraser toutes les données de tous les clients pour tous leurs formulaires remplis et n'est pas réversible. Cette procédure ne peut être annulée et prend beaucoup de temps à compléter."
                            )
                          )
                            setModalMassiveTransferOpen(true);
                        }}
                      >
                        <div className="flex flex-1 items-center">
                          <label className="ml-4 text-red-500 text-[36px]">
                            <SlIcon
                              slot="prefix"
                              name="exclamation-triangle"
                            ></SlIcon>
                          </label>
                          <label>Transfert de tous les clients</label>
                        </div>
                      </SlMenuItem>
                    </SlMenu>
                  </SlDropdown>
                </div>
              </div>
              <div className={styles.user_info_container}>
                <div>
                  <div style={{ fontSize: 18 }}>{selectedClient.numero}</div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    {selectedClient.nom ? selectedClient.nom : "- -"}
                  </div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    {selectedClient.telephone
                      ? selectedClient.telephone
                      : "- -"}
                  </div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    {selectedClient?.clients2?.courriel
                      ? selectedClient.clients2.courriel
                      : "- -"}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    {selectedClient.adresse ? selectedClient.adresse : "- -"}
                  </div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    {selectedClient.ville ? selectedClient.ville : "- -"}
                  </div>
                  <div>
                    <button
                      className="p-2"
                      onClick={() => setselectedClient({})}
                    >
                      Changer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.directory_path_container}>
              {clickedDirectory.value ? (
                <div>
                  <button
                    onClick={() =>
                      handleViewOptionSelect(VIEW_OPTIONS.directories)
                    }
                  >
                    <i class="fas fa-arrow-left"></i>
                  </button>
                  <span>/ {clickedDirectory.value} /</span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.form_preview_container}>
              {authContext.authContext.accessToken.is_admin &&
              selectedViewOption !== VIEW_OPTIONS.fichesConseils &&
              selectedViewOption !== VIEW_OPTIONS.formsListPrint ? (
                <div
                  className={styles.form_preview}
                  onClick={handleNewFormClick}
                >
                  <div
                    className={styles.form_preview_image}
                    style={{
                      borderBottom: "1px solid #3a77ff !important",
                      flex: 1,
                    }}
                  >
                    <i
                      style={{ color: "#3a77ff", fontSize: 34 }}
                      class="fas fa-plus"
                    ></i>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedViewOption === VIEW_OPTIONS.formsListPrint ? (
                <div style={{ overflowX: "hidden" }}>
                  <div className={styles.print_button_forms_list}>
                    <Button onClick={() => print()}>
                      <PrintIcon sx={{ mr: 2 }} />
                      Imprimer
                    </Button>
                  </div>
                  {formsListPrint ? (
                    Object.keys(formsListPrint).map((key) => {
                      return (
                        <div className={styles.printlistforms}>
                          <p>{key ? key : "Dans aucun répertoire"}</p>
                          <ul>
                            {formsListPrint[key].length > 0 ? (
                              formsListPrint[key].map((form) => <li>{form}</li>)
                            ) : (
                              <li>Aucune formulaire</li>
                            )}
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {selectedViewOption === VIEW_OPTIONS.directories ? (
                <div
                  className={styles.form_preview}
                  title={"Fiches conseils"}
                  onClick={() => {
                    setClickedDirectory({ value: "Fiches conseils" });
                    handleViewOptionSelect(VIEW_OPTIONS.fichesConseils);
                  }}
                >
                  <div className={styles.form_preview_image}>
                    <img
                      src={require("../../images/Fiches-conseils.jpg").default}
                    />
                  </div>
                  <div className={styles.form_preview_title}>
                    <div className={styles.form_preview_inner_title}>
                      <span>Fiches conseils</span>
                    </div>
                    <div className={styles.form_options_menu}>
                      <span style={{ color: "gray", fontSize: 14 }}></span>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedViewOption === VIEW_OPTIONS.directories &&
              directories?.length > 0 ? (
                directories.map((directory, index) =>
                  index === 0 ? (
                    <React.Fragment
                      key={directory.value + index}
                    ></React.Fragment>
                  ) : (
                    <div
                      className={styles.form_preview}
                      key={directory.value + index}
                      title={directory.value}
                      onClick={() => {
                        handleDirectorySelect(directory);
                      }}
                    >
                      <div className={styles.form_preview_image}>
                        {directory.img_url ? (
                          <img src={`${BASE_URL}/media/${directory.img_url}`} />
                        ) : (
                          <i class="fas fa-folder"></i>
                        )}
                      </div>
                      <div className={styles.form_preview_title}>
                        <div className={styles.form_preview_inner_title}>
                          <span>{directory.value}</span>
                        </div>
                        <div className={styles.form_options_menu}>
                          <span className="text-red-500 text-sm">
                            {filledFormsDirectories[directory.value]
                              ? `${
                                  filledFormsDirectories[directory.value]
                                } formulaires remplis`
                              : ""}
                          </span>
                          {authContext.authContext.accessToken.is_admin ? (
                            <SlDropdown>
                              <SlIconButton
                                onClick={(e) => e.stopPropagation()}
                                slot="trigger"
                                name="three-dots"
                                label="Options"
                                style={{ fontSize: 24 }}
                              />
                              <SlMenu>
                                <SlMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setClickedDirectory(directory);
                                    setModalPage(PAGES.modifyDirName);
                                    openModal();
                                  }}
                                >
                                  Renommer
                                  <SlIcon
                                    slot="prefix"
                                    name="cursor-text"
                                  ></SlIcon>
                                </SlMenuItem>
                                <SlMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setClickedDirectory(directory);
                                    setModalPage(PAGES.modalModifyDirImg);
                                    openModal();
                                  }}
                                >
                                  Modifier l'image
                                  <SlIcon slot="prefix" name="image"></SlIcon>
                                </SlMenuItem>
                                <SlMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteDir(directory);
                                  }}
                                >
                                  Détruire
                                  <SlIcon slot="prefix" name="trash"></SlIcon>
                                </SlMenuItem>
                              </SlMenu>
                            </SlDropdown>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <></>
              )}
              {selectedViewOption === VIEW_OPTIONS.fichesConseils ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {fichesConseils?.fichesconseils?.map((fiche, index) => (
                    <div
                      key={fiche + index}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <SlCheckbox
                        size="large"
                        value={fiche}
                        onSlChange={setFicheConseilChecked}
                      >
                        {fiche}
                      </SlCheckbox>
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: 20,
                      justifyContent: "center",
                    }}
                  >
                    <input
                      onChange={onFicheConseilChange}
                      id="uploadFicheConseil"
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                    />
                    {authContext.authContext.accessToken.is_admin ? (
                      <SlButton
                        onClick={() =>
                          document.getElementById("uploadFicheConseil").click()
                        }
                        variant="primary"
                      >
                        Ajouter
                      </SlButton>
                    ) : (
                      <></>
                    )}
                    <SlButton
                      style={{ marginLeft: 10 }}
                      disabled={
                        selectedFichesConseils.length > 0 ? false : true
                      }
                      onClick={openFichesConseils}
                      variant="primary"
                      outline
                    >
                      Imprimer
                    </SlButton>
                    <SlButton
                      disabled={
                        selectedFichesConseils.length > 0 ? false : true
                      }
                      style={{ marginLeft: 10 }}
                      onClick={() => setDialogEmailOpen(true)}
                      variant="primary"
                      outline
                    >
                      Courriel
                    </SlButton>
                    {authContext.authContext.accessToken.is_admin ? (
                      <SlButton
                        disabled={
                          selectedFichesConseils.length > 0 ? false : true
                        }
                        style={{ marginLeft: 10 }}
                        variant="danger"
                        outline
                        onClick={(e) => {
                          e.stopPropagation();
                          lockType = 2;
                          setConfirmationDialogLockStatusOpen(true);
                        }}
                      >
                        Retirer
                      </SlButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedViewOption === VIEW_OPTIONS.forms &&
              forms?.length > 0 ? (
                forms.map((form, index) =>
                  form.deleted ? (
                    <React.Fragment
                      key={form._id.$oid + index}
                    ></React.Fragment>
                  ) : (
                    <div
                      className={styles.form_preview}
                      key={form._id.$oid + index}
                      title={`${form.title} - ${
                        form.directory ? form.directory : "Aucune répertoire"
                      }`}
                      data-formid={form._id.$oid}
                      onClick={handleFormSelect}
                      style={{
                        border: isFilledByClient(form._id.$oid)
                          ? "2px solid #2ECC40"
                          : "",
                      }}
                    >
                      <div className={styles.form_preview_image}>
                        {form.img_url ? (
                          <img src={`${BASE_URL}/media/${form.img_url}`} />
                        ) : (
                          <i class="fas fa-file"></i>
                        )}
                      </div>
                      <div className={styles.form_preview_title}>
                        <div className={styles.form_preview_inner_title}>
                          <i class="fas fa-file"></i>
                          <span>{form.title}</span>
                        </div>
                        <div className={styles.form_options_menu}>
                          <div className={styles.form_options_menu_inner}>
                            <span>
                              Crée le {form.created_at.split(" ")[0]}{" "}
                            </span>
                            <span>
                              {form.directory
                                ? form.directory
                                : "Aucun répertoire"}
                            </span>
                          </div>
                          {authContext.authContext.accessToken.is_admin ? (
                            <SlDropdown>
                              <SlIconButton
                                onClick={(e) => e.stopPropagation()}
                                slot="trigger"
                                name="three-dots"
                                label="Options"
                                style={{ fontSize: 24 }}
                              />
                              <SlMenu>
                                <SlMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectedForm = form;
                                    lockType = 1;
                                    setConfirmationDialogLockStatusOpen(true);
                                    // setFormLockStatus(e, form);
                                  }}
                                >
                                  {form.modifiable ||
                                  form.modifiable === undefined
                                    ? "Vérouiller"
                                    : "Déverouiller"}
                                  <SlIcon slot="prefix" name="lock"></SlIcon>
                                </SlMenuItem>
                                {form.modifiable === false ? (
                                  <></>
                                ) : (
                                  <>
                                    <Divider className="my-4" />
                                    <SlMenuItem
                                      onClick={(e) => handleModifyForm(e, form)}
                                    >
                                      Modifier
                                      <SlIcon
                                        slot="prefix"
                                        name="pencil-square"
                                      ></SlIcon>
                                    </SlMenuItem>
                                    <SlMenuItem
                                      onClick={(e) => createCopyForm(e, form)}
                                    >
                                      Créer une copie
                                      <SlIcon
                                        slot="prefix"
                                        name="copy"
                                      ></SlIcon>
                                    </SlMenuItem>
                                    <SlMenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setModalPage(PAGES.modifyDirForm);
                                        setSelectedNewFormDir("");
                                        setSelectedFormModify(form);
                                        openModal();
                                      }}
                                    >
                                      Déplacer de répertoire
                                      <SlIcon
                                        slot="prefix"
                                        name="folder2-open"
                                      ></SlIcon>
                                    </SlMenuItem>
                                    <SlMenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedFormModify(form);
                                        setModalPage(PAGES.modalModifyFormImg);
                                        openModal();
                                      }}
                                    >
                                      Modifier l'image
                                      <SlIcon
                                        slot="prefix"
                                        name="image"
                                      ></SlIcon>
                                    </SlMenuItem>
                                    <Divider className="my-4" />
                                    <SlMenuItem
                                      onClick={(e) => handleDeleteForm(e, form)}
                                    >
                                      Effacer
                                      <SlIcon
                                        slot="prefix"
                                        name="trash"
                                      ></SlIcon>
                                    </SlMenuItem>
                                  </>
                                )}
                              </SlMenu>
                            </SlDropdown>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </GlobalSpinner>
  );
};

export default HomeScreen;
