import React, { useState } from "react";
import styles from "../../../css/ControlTab.module.css";
import { AiOutlineArrowLeft, AiOutlineTable } from "react-icons/ai";
import { MdTitle } from "react-icons/md";
import { LuTextSelect } from "react-icons/lu";

const ControlTab = () => {
  const [tabClosed, setTabClosed] = useState(false);

  const elements = [
    { text: "Titre", icon: <MdTitle size={35} color="white" /> },
    { text: "Zone de texte", icon: <LuTextSelect size={35} color="white" /> },
    { text: "Tableau", icon: <AiOutlineTable size={35} color="white" /> },
  ];

  const toggleTab = () => {
    setTabClosed((current) => !current);
  };

  return (
    <div
      className={
        tabClosed ? styles.main_container_closed : styles.main_container
      }
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <label>Ajouter des éléments</label>
        </div>
        <div className={styles.close_button} onClick={toggleTab}>
          <AiOutlineArrowLeft size={30} color="white" />
        </div>
      </div>

      <div className={styles.controls_container}>
        {elements.map((element, index) => (
          <div key={element.text + index} className={styles.element_container}>
            <div style={{ backgroundColor: "#13515c", padding: 5 }}>
              {element.icon}
            </div>
            <div style={{ marginLeft: 15 }}>{element.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ControlTab;
