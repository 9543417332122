import React from "react";
import { CircularProgress } from "@mui/material";

const Progress = (props) => {
  return (
    <>
      {props?.loading ? (
        <div className="flex flex-1 justify-center items-center flex-col">
          <CircularProgress />

          {props?.showMassiveTransferMessage ? (
            <div className="flex flex-col text-center mt-8">
              <div>
                Nous essayons de récupérer tous les clients et leurs formulaires
                respectifs.
              </div>
              <div>
                Le traitement peut prendre du temps et figé votre navigateur.
              </div>
              <div>Une fois cette étape terminé, le transfert va débuter.</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default Progress;
