import React from "react";
import styles from "../../css/FormCreation.module.css";
import ControlTab from "./ControlTab/index";
import Form from "./Form";

const FormCreation = () => {
  return (
    <div className={styles.main_container}>
      {/* <ControlTab /> */}
      <Form />
    </div>
  );
};

export default FormCreation;
