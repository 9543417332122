import axios from "axios";
import { BASE_URL } from "../constants";

const api_endpoints = {
  save_form: (formData) => {
    return axios.post(`${BASE_URL}/save_form`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  submit_form: (fields, user, client, form_id, client_email) => {
    return axios.post(`${BASE_URL}/submit_form`, {
      fields: fields,
      client: client,
      user: user,
      form_id,
      client_email,
    });
  },
  transfer_form: (fields, form_name, client, user) => {
    return axios.post(`${BASE_URL}/transfer_form`, {
      fields: fields,
      form_name: form_name,
      client: client,
      user: user,
    });
  },
  get_last_submited_form: (user_num, form_id) => {
    return axios.get(
      `${BASE_URL}/get_last_submited_form?user_num=${user_num}&form_id=${form_id}`
    );
  },
  get_all_forms: (client_number) => {
    return axios.get(
      `${BASE_URL}/get_all_forms?client_number=${client_number}`
    );
  },
  get_form: (form_id) => {
    return axios.get(`${BASE_URL}/get_form?form_id=${form_id}`);
  },
  get_all_directories: (client_number) => {
    return axios.get(
      `${BASE_URL}/get_all_directories?client_number=${client_number}`
    );
  },
  create_directory: (formData) => {
    return axios.post(`${BASE_URL}/create_directory`, formData);
  },
  get_forms_from_dir: (directory, client_number) => {
    return axios.get(
      `${BASE_URL}/get_forms_from_dir?directory=${directory}&client_number=${client_number}`
    );
  },
  get_users: (username) => {
    return axios.get(`${BASE_URL}/get_users?username=${username}`);
  },
  delete_users: (users) => {
    return axios.post(`${BASE_URL}/delete_users`, {
      users: JSON.stringify(users),
    });
  },
  modify_user: (user_data) => {
    return axios.post(`${BASE_URL}/modify_user`, {
      user_data: JSON.stringify(user_data),
    });
  },
  change_form_dir: (form_id, form_title, new_directory) => {
    return axios.post(`${BASE_URL}/change_form_dir`, {
      form_id: form_id,
      form_title: form_title,
      new_directory: new_directory,
    });
  },
  delete_form: (form_id) => {
    return axios.post(`${BASE_URL}/delete_form`, {
      form_id: form_id,
    });
  },
  rename_dir: (new_dir_name, directory) => {
    return axios.post(`${BASE_URL}/rename_dir`, {
      new_dir_name: new_dir_name,
      directory: directory,
    });
  },
  delete_dir: (directory) => {
    return axios.post(`${BASE_URL}/delete_dir`, {
      directory: directory,
    });
  },
  get_clients: (signal, filterValue) => {
    return axios.get(`${BASE_URL}/get_clients?filterValue=${filterValue}`, {
      signal: signal,
    });
  },
  get_client_data: (noclient) => {
    return axios.get(`${BASE_URL}/get_client_data?noclient=${noclient}`);
  },
  modify_image_dir: (formData) => {
    return axios.post(`${BASE_URL}/modify_image_dir`, formData);
  },
  modify_image_form: (formData) => {
    return axios.post(`${BASE_URL}/modify_image_form`, formData);
  },
  get_list_fiches_conseils: () => {
    return axios.get(`${BASE_URL}/getListFichesConseils`);
  },
  email_ficheconseils: (selectedFichesConseils, clientEmail, client_number) => {
    return axios.post(`${BASE_URL}/email_ficheconseils`, {
      selectedFichesConseils: selectedFichesConseils,
      clientEmail: clientEmail,
      client_number: client_number,
    });
  },
  archive_submited_form: (form_id, filled_by) => {
    return axios.post(`${BASE_URL}/archive_submited_form`, {
      form_id: form_id,
      filled_by: filled_by,
    });
  },
  get_user_archives: (form_id, filled_by) => {
    return axios.get(
      `${BASE_URL}/get_user_archives?form_id=${form_id}&filled_by=${filled_by}`
    );
  },
  upload_fiche_conseil: (formData) => {
    return axios.post(`${BASE_URL}/upload_fiche_conseil`, formData);
  },
  remove_fiche_conseil: (fiche_names) => {
    return axios.post(`${BASE_URL}/remove_fiche_conseil`, {
      fiche_names: JSON.stringify(fiche_names),
    });
  },
  get_client_forms_transfer: (client_number) => {
    return axios.get(
      `${BASE_URL}/get_client_forms_transfer?client_number=${client_number}`
    );
  },
  get_form_by_name: (form_name) => {
    return axios.get(`${BASE_URL}/get_form_by_name?form_name=${form_name}`);
  },
  get_old_form_data: (form_name, client_number) => {
    return axios.get(
      `${BASE_URL}/get_old_form_data?form_name=${form_name}&client_number=${client_number}`
    );
  },
  get_all_clients: () => {
    return axios.get(`${BASE_URL}/get_all_clients`);
  },
  get_all_clients_forms_status: () => {
    return axios.get(`${BASE_URL}/get_all_clients_forms_status`);
  },
  set_lock_status_form: (form_id, modifiable_status) => {
    return axios.post(`${BASE_URL}/set_lock_status_form`, {
      form_id: form_id,
      modifiable_status: modifiable_status,
    });
  },
  create_copy_form: (form) => {
    return axios.post(`${BASE_URL}/create_copy_form`, { form: form });
  },
  getEncryptedUsername: (username) => {
    return axios.get(`${BASE_URL}/getEncryptedUsername?username=${username}`);
  },
};

export default api_endpoints;
