// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.air-datepicker{
    font-size: 26px !important;
    width: auto !important;
    --adp-mobile-day-cell-height: 60px !important;
    --adp-mobile-month-cell-height: 60px !important;
    --adp-mobile-year-cell-height: 60px !important;
}

.air-datepicker-cell{
    padding: 30px !important;
}

.air-datepicker-body--day-name{
    color: #3a77ff !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/AirDatepicker.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,sBAAsB;IACtB,6CAA6C;IAC7C,+CAA+C;IAC/C,8CAA8C;AAClD;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".air-datepicker{\r\n    font-size: 26px !important;\r\n    width: auto !important;\r\n    --adp-mobile-day-cell-height: 60px !important;\r\n    --adp-mobile-month-cell-height: 60px !important;\r\n    --adp-mobile-year-cell-height: 60px !important;\r\n}\r\n\r\n.air-datepicker-cell{\r\n    padding: 30px !important;\r\n}\r\n\r\n.air-datepicker-body--day-name{\r\n    color: #3a77ff !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
