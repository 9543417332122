import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#app");

const ReactModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      style={{
        ...Modal.defaultStyles,
        overlay: { backgroundColor: "rgba(0,0,0,0.3", ...props.overlayStyle },
        content: {
          width: "50vw",
          height: "50vh",
          marginLeft: "auto",
          marginRight: "auto",
          top: "50%",
          transform: "translate(0, -50%)",
          ...props.contentStyle,
        },
      }}
      onRequestClose={props.onRequestClose}
    >
      {props.children}
    </Modal>
  );
};

export default ReactModal;
