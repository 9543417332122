import React, { useEffect, useState } from "react";
import SignaturePad from "signature_pad";
import styles from "../../css/SignatureCanvas.module.css";
import ReactModal from "../Modal";

let canvas = null;
let signaturePad = null;

const SignatureCanvas = (props) => {
  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        initSignaturePad();
        resizeCanvas();
        handleWindowResize();
        insertSignature();
      }, 0);
    }
  }, [props.show]);

  const initSignaturePad = () => {
    canvas = document.getElementById("signature_canvas");
    signaturePad = new SignaturePad(canvas, {
      backgroundColor: "#F0F0F0",
      minWidth: 1,
      maxWidth: 2.5,
      penColor: "rgba(0, 145, 198, 1)",
    });
  };

  const resizeCanvas = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear();
  };

  const insertSignature = () => {
    if (props.signatures[props.selectedSignature] !== "") {
      signaturePad
        .fromDataURL(props.signatures[props.selectedSignature])
        .then((r) => {});
    }
  };

  const handleWindowResize = () => {
    window.onresize = () => {
      resizeCanvas();
    };
  };

  const closeCanvas = () => {
    props.setShow(false);
  };

  const handleClear = () => {
    signaturePad.clear();
  };

  const handleOk = () => {
    let signatureValue = "";
    let signatureId = props.selectedSignature;

    if (!signaturePad.isEmpty()) {
      signatureValue = signaturePad.toDataURL();
    }

    props.setSignatures({
      ...props.signatures,
      [signatureId]: signatureValue,
    });

    closeCanvas();
  };

  return (
    <ReactModal
      isOpen={props.show}
      onRequestCose={closeCanvas}
      contentStyle={{ width: "650px", height: "350px" }}
    >
      <div className={styles.canvas_inner_container}>
        <canvas id={"signature_canvas"}></canvas>
        <div>
          <i style={{ color: "gray" }}>Signature</i>
        </div>
        <div className={styles.footer}>
          <button style={{ backgroundColor: "#f2d100" }} onClick={handleClear}>
            Effacer
          </button>
          <button style={{ backgroundColor: "#FF4136" }} onClick={closeCanvas}>
            Annuler
          </button>
          <button style={{ backgroundColor: "#3a77ff" }} onClick={handleOk}>
            Ok
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default SignatureCanvas;
