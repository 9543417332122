// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CUH6rlNblga_a5NbSKVn{
    width: 320px;
    background-color: #2795a9;
    flex-direction: column;
    transition: 0.5s;
}

.nIwv5k9WBKpQaxRqi0qF{
    transform: translate(-300px);
    width: 350px;
    transition: 0.5s;
    background-color: #2795a9;
    flex-direction: column;
}

.nIwv5k9WBKpQaxRqi0qF::after{
    display: none;
}

._3t4GO96m6sk_1yTvOEB{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SR8DRMTzGjK14LH9_qgC{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    font-size: 20px;
    color: white;
}

.nQsp963QyriBo_CPGI_4{
    cursor: pointer;
    margin-right: 15px;
}

.PhurYVZkoF4V1KKgEldz{
    flex: 1;
    overflow-y: auto;
    background-color: #1f7e8f;
    flex-direction: column;
}

.PlBrYuFBM69Dy0uB230L{
    flex-direction: row;
    border-bottom: 1px solid gray;
    font-size: 20px;
    color: white;
    align-items: center;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/ControlTab.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,OAAO;IACP,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".main_container{\r\n    width: 320px;\r\n    background-color: #2795a9;\r\n    flex-direction: column;\r\n    transition: 0.5s;\r\n}\r\n\r\n.main_container_closed{\r\n    transform: translate(-300px);\r\n    width: 350px;\r\n    transition: 0.5s;\r\n    background-color: #2795a9;\r\n    flex-direction: column;\r\n}\r\n\r\n.main_container_closed::after{\r\n    display: none;\r\n}\r\n\r\n.header{\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.title{\r\n    padding-top: 15px;\r\n    padding-bottom: 15px;\r\n    padding-left: 15px;\r\n    font-size: 20px;\r\n    color: white;\r\n}\r\n\r\n.close_button{\r\n    cursor: pointer;\r\n    margin-right: 15px;\r\n}\r\n\r\n.controls_container{\r\n    flex: 1;\r\n    overflow-y: auto;\r\n    background-color: #1f7e8f;\r\n    flex-direction: column;\r\n}\r\n\r\n.element_container{\r\n    flex-direction: row;\r\n    border-bottom: 1px solid gray;\r\n    font-size: 20px;\r\n    color: white;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `CUH6rlNblga_a5NbSKVn`,
	"main_container_closed": `nIwv5k9WBKpQaxRqi0qF`,
	"header": `_3t4GO96m6sk_1yTvOEB`,
	"title": `SR8DRMTzGjK14LH9_qgC`,
	"close_button": `nQsp963QyriBo_CPGI_4`,
	"controls_container": `PhurYVZkoF4V1KKgEldz`,
	"element_container": `PlBrYuFBM69Dy0uB230L`
};
export default ___CSS_LOADER_EXPORT___;
