import React, { useContext, useEffect, useState } from "react";
import styles from "../../css/Admin.module.css";
import AuthContext from "../../context/AuthContext";
import api_endpoints from "../../api/index";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import DataTable from "react-data-table-component";
import ReactModal from "../Modal";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import axios from "axios";
import { AUTH_URL } from "../../constants";
import { useNavigate } from "react-router-dom";

const VALID_PIN = "%VIVA2023%";

const Admin = () => {
  const MODAL_PAGE = {
    new_user: "new_user",
    edit: "edit_user",
  };

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [users, setUsers] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPage, setModalPage] = useState(MODAL_PAGE.new_user);
  const [enableDeleteUsers, setEnableDeleteUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [userToModify, setUserToModify] = useState({});
  const [validCode, setValidCode] = useState(false);
  const [codeInput, setCodeInput] = useState("");

  const customStyles = {
    table: {
      style: {
        backgroundColor: "transparent",
      },
    },
    headCells: {
      style: {
        padding: "5px",
        fontSize: "16px",
        backgroundColor: "transparent",
      },
    },
    headRow: {
      style: {
        backgroundColor: "transparent",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "14px",
        backgroundColor: "transparent",
      },
    },
    rows: {
      style: {
        backgroundColor: "transparent",
      },
      highlightOnHoverStyle: {
        backgroundColor: "rgba(58, 119, 255, 0.2)",
        border: "0",
        outline: "unset",
      },
    },
    noData: {
      style: {
        backgroundColor: "transparent",
      },
    },
  };

  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Admin",
      cell: (row) => checkboxComponent(row.is_admin),
      selector: (row) => row.is_admin,
      width: "100px",
    },
    {
      name: "Super user",
      cell: (row) => checkboxComponent(row.is_super_user),
      selector: (row) => row.is_super_user,
      width: "100px",
    },
    {
      name: "Last login",
      selector: (row) => row.last_login,
      sortable: true,
    },
    {
      name: "Created at",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      selector: (row) => row.is_active,
      width: "100px",
      conditionalCellStyles: [
        {
          when: (row) => row.is_active === "Actif",
          style: { color: "#3a77ff" },
        },
        {
          when: (row) => row.is_active === "Effacé",
          style: { color: "tomato" },
        },
      ],
    },
    {
      cell: (row) => modifyComponent(row.modify),
      selector: (row) => row.modify,
      width: "70px",
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const checkboxComponent = (field) => {
    return (
      <div className={styles.table_checkbox_container}>
        <input type="checkbox" checked={field} onChange={() => {}}></input>
      </div>
    );
  };

  const handleModify = (e) => {
    setUserToModify(e);
    openModal();
    setModalPage(MODAL_PAGE.edit);
  };

  const modifyComponent = (user) => {
    return (
      <SlIconButton
        style={{ fontSize: "16px" }}
        name="pencil-square"
        label="Modify"
        onClick={() => handleModify(user)}
      />
    );
  };

  const fetchUsers = async () => {
    let response = await api_endpoints.get_users(filterValue);
    let parsedData = JSON.parse(response.data);
    let data = [];

    for (let i = 0; i < parsedData.length; i++) {
      data.push({
        id: parsedData[i].id,
        username: parsedData[i].username,
        is_admin: parsedData[i].is_admin,
        is_super_user: parsedData[i].is_super_user,
        last_login: parsedData[i].last_login?.$date.toString(),
        created_at: parsedData[i].created_at.$date.toString(),
        is_active: parsedData[i].is_active ? "Actif" : "Effacé",
        modify: parsedData[i],
      });
    }

    setUsers(data);
  };

  const handleSelectedRow = (e) => {
    if (e.selectedCount > 0) {
      allowDeleteUsers();
      setSelectedUsers(e.selectedRows);
    } else {
      disableDEleteUsers();
      setSelectedUsers([]);
    }
  };

  const deleteUsers = async () => {
    if (!confirm("Êtes-vous sûr de vouloir effacer ces utilisateurs?")) {
      return;
    }

    let usersIds = [];

    for (let user of selectedUsers) {
      usersIds.push(user.id);
    }

    let response = await api_endpoints.delete_users(usersIds);

    if (response.status === 200) {
      fetchUsers();
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setPassword("");
    setUsername("");
  };

  const allowDeleteUsers = () => {
    setEnableDeleteUsers(true);
  };

  const disableDEleteUsers = () => {
    setEnableDeleteUsers(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const modifyUser = async () => {
    let response = await api_endpoints.modify_user(userToModify);

    if (response.status === 200) {
      fetchUsers();
      closeModal();
    }
  };

  const createUser = async () => {
    if (username.length === 0 || password === 0) return;

    let response = await axios.post(`${AUTH_URL}/signup`, {
      username: username,
      password: password,
    });

    if (response.status === 200) {
      fetchUsers();
      closeModal();
    }
  };

  const addNewUser = () => {
    openModal();
    setModalPage(MODAL_PAGE.new_user);
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.value === "is_active_checkbox") {
      setUserToModify({ ...userToModify, is_active: !e.target.checked });
    } else if (e.target.value === "is_admin_checkbox") {
      setUserToModify({ ...userToModify, is_admin: e.target.checked });
    } else if (e.target.value === "is_super_user_checkbox") {
      setUserToModify({ ...userToModify, is_super_user: e.target.checked });
    }
  };

  const modalModifyUserPage = () => {
    return (
      <div className={styles.modal_new_user_container} id="modify_page">
        <div className={styles.modal_new_user_title}>Modifications</div>
        <div className={styles.modal_modify_user_input_container}>
          <div>
            <label>Nom d'utilisateur</label>
            <label>{userToModify.username}</label>
          </div>
          <div>
            <label>Effacé</label>
            <input
              type="checkbox"
              checked={!userToModify.is_active}
              onChange={handleCheckboxChange}
              value="is_active_checkbox"
            />
          </div>
          <div>
            <label>Admin</label>
            <input
              type="checkbox"
              checked={userToModify.is_admin}
              onChange={handleCheckboxChange}
              value="is_admin_checkbox"
            />
          </div>
          <div>
            <label>Super user</label>
            <input
              type="checkbox"
              checked={userToModify.is_super_user}
              disabled={!authContext.authContext.accessToken.is_super_user}
              onChange={handleCheckboxChange}
              value="is_super_user_checkbox"
            />
          </div>
        </div>
        <div className={styles.modal_new_user_footer}>
          <SlButton className={styles.cancel_button} onClick={closeModal}>
            Annuler
          </SlButton>
          <SlButton className={styles.create_user_button} onClick={modifyUser}>
            Modifier
          </SlButton>
        </div>
      </div>
    );
  };

  const modalNewUserPage = () => {
    return (
      <div className={styles.modal_new_user_container}>
        <div className={styles.modal_new_user_title}>Nouveau utilisateur</div>
        <div className={styles.modal_new_user_input_container}>
          <SlInput
            onInput={handleUsernameChange}
            placeholder="Nom d'utilisateur"
          />
          <SlInput
            onInput={handlePasswordChange}
            type="password"
            placeholder="Mot de passe"
            password-toggle
          />
        </div>
        <div className={styles.modal_new_user_footer}>
          <SlButton className={styles.cancel_button} onClick={closeModal}>
            Annuler
          </SlButton>
          <SlButton className={styles.create_user_button} onClick={createUser}>
            Créer
          </SlButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {!validCode ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3>Entrer le code d'accès</h3>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SlInput
              placeholder="Code d'accès"
              value={codeInput}
              onInput={(e) => {
                setCodeInput(e.target.value);
              }}
              type="password"
              password-toggle
            />
            <SlButton
              onClick={() => {
                if (codeInput === VALID_PIN) {
                  setValidCode(true);
                }
              }}
            >
              Confirmer
            </SlButton>
          </div>
        </div>
      ) : (
        <>
          <ReactModal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            contentStyle={
              modalPage === MODAL_PAGE.new_user
                ? { height: "250px", width: "600px" }
                : modalPage === MODAL_PAGE.edit
                ? { height: "350px", width: "600px" }
                : {}
            }
          >
            {modalPage === MODAL_PAGE.new_user ? (
              modalNewUserPage()
            ) : modalPage === MODAL_PAGE.edit ? (
              modalModifyUserPage()
            ) : (
              <></>
            )}
          </ReactModal>
          <div className={styles.main_container}>
            <div className={styles.header}>
              <h1 style={{ fontWeight: 500 }}>
                Bienvenue, {authContext.authContext.accessToken.username}, à la
                zone administrative
              </h1>
              <div>
                <SlButton
                  className={styles.return_homescreen}
                  onClick={() => navigate("/")}
                >
                  Sortir
                </SlButton>
                <SlButton
                  className={styles.return_homescreen}
                  onClick={() => print()}
                >
                  <SlIcon name="printer" style={{ marginRight: 10 }} />
                  Imprimer
                </SlButton>
              </div>
            </div>
            <div className={styles.controls_container}>
              <div>
                <SlButton
                  className={styles.new_user_button}
                  onClick={addNewUser}
                >
                  <SlIcon slot="prefix" name="plus-lg"></SlIcon>
                  Nouveau utilisateur
                </SlButton>
                <SlButton
                  className={styles.delete_users_button}
                  disabled={!enableDeleteUsers}
                  onClick={deleteUsers}
                >
                  <SlIcon slot="prefix" name="trash"></SlIcon>
                  Supprimer
                </SlButton>
              </div>
              <div className={styles.filter_container}>
                <SlInput
                  placeholder="Filtrer par nom"
                  onInput={handleFilterChange}
                />
                <SlButton className={styles.filter_button} onClick={fetchUsers}>
                  <SlIcon slot="prefix" name="search"></SlIcon>
                  Filtrer
                </SlButton>
              </div>
            </div>
            <div className={styles.table_container}>
              <DataTable
                columns={columns}
                data={users}
                customStyles={customStyles}
                selectableRows={true}
                onSelectedRowsChange={handleSelectedRow}
                highlightOnHover
                noDataComponent={"Aucun utilisateur trouvé"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Admin;
