import React, { Component } from "react";
import AuthContext from "./AuthContext";
import axios from "axios";
import { AUTH_URL } from "../constants";
import jwt_decode from "jwt-decode";

/**
 * Authentication Provider permettant d'authentifier ou effacer son jeton d'authentification.
 * Avec ce jeton, on peut obtenir l'information de la base de données qu'il/elle peut accéder et
 * de s'assurer de vérifier que chaque requêtes sont fait par un utilisateur valide.
 */
class AuthProvider extends Component {
  constructor(props) {
    super(props);

    let accessToken = localStorage.getItem("accessTokenIstheraForms");

    this.state = {
      accessToken: accessToken
        ? {
            ...jwt_decode(accessToken),
            token: JSON.parse(accessToken).data.token,
          }
        : null,
      loggedIn: accessToken ? true : false,
    };

    // contextData contient les variables ou les fonctions que nous
    // allons rendre visible et disponible dans le contexte de chaque
    // classes et composantes qui le possèdera. Cela veut dire que lorsqu'on
    // fera this.context dans une composante, on pourra voir accessToken, etc.
    this.contextData = {
      accessToken: this.state.accessToken,
      loggedIn: this.state.loggedIn,
      loginUser: this.loginUser,
      logoutUser: this.logoutUser,
      isTokenExpired: this.isTokenExpired,
    };
  }

  componentDidMount = async () => {
    if (window.sessionContext?.username && window.sessionContext?.password) {
      this.logoutUser();
      await this.verifyOldAppUser();
      window.sessionContext = null;
    }

    if (this.isTokenExpired()) {
      this.logoutUser();
    }

    this.verifyRedirectURL();
  };

  verifyRedirectURL = () => {
    if (window.location.href.indexOf("/redirect/") > -1) {
      let url = `${process.env.SERVER_HOSTNAME}:${process.env.SERVER_PORT}/`;
      window.location.href = url;
    }
  };

  verifyOldAppUser = async () => {
    let response = await this.loginOldAppUser(
      window.sessionContext.username,
      window.sessionContext.password
    );
  };

  loginOldAppUser = async (username, password) => {
    await this.loginUser(username, password);
  };

  setAccessTokens = (data) => {
    this.setState({ accessToken: data });
  };

  setLoggedIn = (value) => {
    this.setState({ loggedIn: value });
  };

  requestToken = (body) => {
    return axios.post(`${AUTH_URL}/login`, body);
  };

  /**
   * Sauvegarder les tokens dans les variables states et aussi dans le localStorage.
   * Le token dans le logal storage sera encrypté par le back-end Django
   * @param {*} token contient le access token et le refresh token de l'utilisateur que
   * l'on veut sauvegarder.
   */
  saveToken = (token) => {
    localStorage.setItem("accessTokenIstheraForms", JSON.stringify(token));
    this.setAccessTokens(token);
    this.setLoggedIn(true);
  };

  /**
   * Mettre les variables states de token à null et retirer les tokens
   * du localStorage
   */
  deleteToken = () => {
    this.setAccessTokens(null);
    localStorage.removeItem("accessTokenIstheraForms");
  };

  loginUser = async (username, password) => {
    let body = { username: username, password: password };
    let response = null;

    try {
      response = await this.requestToken(body);
      this.saveToken(response.data);
    } catch {
      this.deleteToken();
    }

    return response ? true : false;
  };

  logoutUser = () => {
    this.deleteToken();
  };

  isTokenExpired = () => {
    if (this.state.accessToken) {
      return Date.now() <= this.state.accessToken.exp * 1000 ? false : true;
    }
    return false;
  };

  render() {
    return (
      <AuthContext.Provider value={{ authContext: this.contextData }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
