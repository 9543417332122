import React, { useState, useRef, useEffect } from "react";
import "../../../css/GlobalEditor.css";
import SunEditor from "suneditor-react";
import "suneditor/src/assets/css/suneditor.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
} from "suneditor/src/plugins";
import { customPlugins } from "../../EditorPlugins";
import { customContainer } from "../../EditorContainer";
import api_endpoints from "../../../api";
import { useNavigate, useLocation } from "react-router-dom";
import SlButton from "@shoelace-style/shoelace/dist/react/button";

var contentNode = null;
var highestIDSavedForm = null;

const Form = () => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [documentTitle, setDocumentTitle] = useState("Nouveau formulaire");
  const [savingDoc, setSavingDoc] = useState(false);
  const [docSaved, setDocSaved] = useState(false);

  useEffect(() => {
    if (state.form_data) {
      setDocumentTitle(state.form_data.title);
      // setDefaultContentEditor();
    }
  }, [state.form_data]);

  useEffect(() => {
    // console.log(state);
    setDefaultContentEditor();
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      handleBackButton();
    };
  }, []);

  useEffect(() => {
    if (savingDoc) {
      timeoutDocSaved();
    }
  }, [savingDoc]);

  const setDefaultContentEditor = () => {
    setContentNode();

    setTimeout(() => {
      if (state?.form_data?.body) contentNode.innerHTML = state.form_data.body;
      highestIDSavedForm = getHighestIDSavedForm();
    }, 0);
  };

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const getHighestID = (nodeList, start = 0) => {
    let lastId = start;

    for (const node of nodeList) {
      let splitID = node.id.split("_");
      let id = Number(splitID[splitID.length - 1]);
      if (lastId <= id) lastId = id + 1;
    }
    return lastId;
  };

  const getHighestIDSavedForm = () => {
    const tempNode = document.getElementsByClassName(
      "se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
    )[0];

    let userInputs = [
      tempNode.querySelectorAll("input[type=text]"),
      tempNode.querySelectorAll("input[type=checkbox]"),
      tempNode.querySelectorAll("input[type=date]"),
      tempNode.querySelectorAll("textarea"),
      tempNode.querySelectorAll("button"),
      tempNode.querySelectorAll("img[alt='dessin']"),
    ];

    let IDStartInputText = getHighestID(userInputs[0]);
    let IDStartInputCheckbox = getHighestID(userInputs[1]);
    let IDStartInputDate = getHighestID(userInputs[2]);
    let IDStartTextarea = getHighestID(userInputs[3]);
    let IDStartButton = getHighestID(userInputs[4]);
    let IDStartImg = getHighestID(userInputs[5]);

    return {
      inputText: IDStartInputText,
      IDStartInputCheckbox: IDStartInputCheckbox,
      IDStartInputDate: IDStartInputDate,
      IDStartTextarea: IDStartTextarea,
      IDStartButton: IDStartButton,
      IDStartImg: IDStartImg,
    };
  };

  const generateFieldIDs = () => {
    let sequenceInputCount = 1;
    let userInputs = [
      contentNode.querySelectorAll("input[type=text]"),
      contentNode.querySelectorAll("input[type=checkbox]"),
      contentNode.querySelectorAll("input[type=date]"),
      contentNode.querySelectorAll("textarea"),
      contentNode.querySelectorAll("button"),
      contentNode.querySelectorAll("img[alt='dessin']"),
    ];

    for (let input of userInputs) {
      input.forEach((element, index) => {
        if (element.dataset.command === "sequence") {
          element.dataset.sequenceValue = sequenceInputCount++;
        }

        if (element.tagName === "BUTTON") {
          if (element.name === "") {
            const idIndex = getHighestID(
              input,
              highestIDSavedForm.IDStartButton
            );
            element.name =
              element.id = `${element.tagName}_${element.dataset.type}_${idIndex}`;
          }
        } else if (element.tagName === "IMG") {
          if (element.name === "" && element.id === "") {
            const idIndex = getHighestID(input, highestIDSavedForm.IDStartImg);
            element.name = element.id = `${element.alt}_${idIndex}`;
          }
        } else {
          if (element.name === "" && element.id === "") {
            let idIndex = 0;

            if (element.type === "checkbox")
              idIndex = getHighestID(
                input,
                highestIDSavedForm.IDStartInputCheckbox
              );
            else if (element.type === "textarea")
              idIndex = getHighestID(input, highestIDSavedForm.IDStartTextarea);

            element.name =
              element.id = `${element.tagName}_${element.type}_${idIndex}`;
          }
        }
      });
    }
  };

  const setContentNode = () => {
    contentNode = document.getElementsByClassName(
      "se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
    )[0];
  };

  const affectComputedStyle = () => {
    let allInnerElements = contentNode.getElementsByTagName("DIV");
    const allSpanElements = contentNode.getElementsByTagName("span");

    // height:${computedStyle.height};
    for (let element of allInnerElements) {
      let computedStyle = getComputedStyle(element);
      element.style.cssText = `
      font-size:${computedStyle.fontSize};
      font-style:${computedStyle.fontStyle};
      font-family:${computedStyle.fontFamily};
      text-align:${computedStyle.textAlign};
      width:${computedStyle.width};
      height:min-content !important;
      display:${computedStyle.display};
      justify-content:${computedStyle.justifyContent};
      float:${computedStyle.float};
      margin-right:${computedStyle.marginRight};
      margin-left:${computedStyle.marginLeft};
      text-align:${computedStyle.textAlign};
      vertical-align:${computedStyle.verticalAlign};
      align-items:${computedStyle.alignItems};
      `;
    }

    for (const element of allSpanElements) {
      const computedStyle = getComputedStyle(element);
      element.style.cssText = `
      color:${computedStyle.color};
      background-color:${computedStyle.backgroundColor};
      font-size:${computedStyle.fontSize};
      font-style:${computedStyle.fontStyle};
      font-family:${computedStyle.fontFamily};
      `;
    }
  };

  const timeoutDocSaved = () => {
    setTimeout(() => {
      setDocSaved(false);
    }, 1500);
  };

  const handleSave = async () => {
    setSavingDoc(true);
    // setContentNode();
    affectComputedStyle();
    generateFieldIDs();
    let modify = state.form_data ? true : false;
    let fd = new FormData();

    if (!modify && state.imageForm)
      fd.append("image", state.imageForm), state.imageForm.name;

    fd.append("directory", state.directory);
    fd.append("title", documentTitle);
    fd.append("form", contentNode.innerHTML);
    fd.append("form_data", JSON.stringify(state.form_data));
    fd.append("modify", modify);

    let response = await api_endpoints.save_form(fd);

    if (response.status === 200) {
      setDocSaved(true);
    }

    if (response.status === 201) {
      setDocSaved(true);
      state.form_data = JSON.parse(response.data);
    }

    setSavingDoc(false);
  };

  const handleTitleChange = (event) => {
    setDocumentTitle(event.target.value);
  };

  const handleBackButton = () => {
    if (
      confirm(
        "Voulez-vous quitter cette page ? Les changements pas sauvegardés seront perdus."
      )
    ) {
      navigate("/", { state: { client: state.client } });
    }
  };

  const calculateCharTextArea = (resizeObserverEntry) => {
    let target = resizeObserverEntry[0].target;
    let inputWidth = parseFloat(target.getBoundingClientRect().width);
    let characterWidth = 11;
    let maxCharsInput = 0;

    if (target.dataset.command === undefined || target.dataset.command === "") {
      if (target.dataset.type === "input") {
        maxCharsInput = Math.round(inputWidth / characterWidth);
      } else {
        let lines = parseInt(target.clientHeight / 20);
        maxCharsInput = Math.round((inputWidth * lines) / characterWidth);
      }

      target.placeholder = `${maxCharsInput} caractères`;
      target.maxLength = maxCharsInput;
    }
  };

  const handleTextAreaResize = () => {
    // setContentNode();
    let inputs = contentNode.querySelectorAll("textarea");

    for (let input of inputs) {
      if (input.dataset.type === "input" && input.dataset.type === "textarea") {
        new ResizeObserver(calculateCharTextArea).observe(input);

        input.oninput = (e) => {
          e.target.value = "";
        };
      }
    }
  };

  const handleDateInput = () => {
    // setContentNode();
    let inputs = contentNode.querySelectorAll("textarea[data-type=date]");

    for (let input of inputs) {
      input.oninput = (e) => {
        e.target.value = "";
      };
    }
  };

  const handleEditorChange = (e) => {
    handleTextAreaResize();
    handleDateInput();
  };

  return (
    <>
      <div className="header">
        <div className="left_header">
          <div className="img_container">
            <img
              src={
                require("../../../images/newlogo-toutseul-juste-design.png")
                  .default
              }
            />
          </div>
          <div className="title_container">
            <input value={documentTitle} onInput={handleTitleChange} />
            <span>
              {state.form_data?.directory
                ? `Pour le répertoire ${state.form_data.directory}`
                : state.directory
                ? `Pour le répertoire ${state.directory}`
                : "Aucun répertoire selectionnée"}
            </span>
          </div>
        </div>
        <div className="header_buttons">
          <div
            style={{
              color: "green",
              fontStyle: "italic",
              fontSize: 16,
              marginRight: 20,
              visibility: docSaved ? "visible" : "hidden",
            }}
          >
            Formulaire sauvegardé avec succès
          </div>
          <SlButton onClick={handleBackButton}>
            <i class="fas fa-arrow-left"></i>
            Retour
          </SlButton>
          <SlButton onClick={handleSave} loading={savingDoc}>
            <i class="far fa-save"></i>
            Sauvegarder
          </SlButton>
        </div>
      </div>

      <SunEditor
        lang="fr"
        autoFocus={true}
        setAllPlugins={false}
        getSunEditorInstance={getSunEditorInstance}
        defaultValue={state.form_data ? state.form_data.body : ""}
        onChange={handleEditorChange}
        setOptions={{
          defaultTag: "div",
          resizeEnable: false,
          showPathLabel: false,
          defaultStyle:
            "font-family: Calibri; font-size: 16px; display: flex; flex: 1; flex-direction: column; flex-basis: min-content; width: 100%; height: fit-content !important;",
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            hiliteColor,
            formatBlock,
            list,
            table,
            template,
            textStyle,
            image,
            customPlugins.input_plugin,
            customPlugins.date_plugin,
            customPlugins.textarea_plugin,
            customPlugins.checkbox_plugin,
            customPlugins.signature,
            customPlugins.client_email_plugin,
            customPlugins.client_phone_plugin,
            customPlugins.client_name_plugin,
            customPlugins.client_lastname_plugin,
            customPlugins.client_combined_name_plugin,
            customPlugins.technician_name_plugin,
            customPlugins.sequence_plugin,
            customContainer,
          ],
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            // ["paragraphStyle"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            // ["removeFormat"],
            // ["outdent", "indent"],
            [
              "align",
              // "horizontalRule",
              "list",
              //  "lineHeight"
            ],
            ["table", "image"],
            [
              "customInput",
              "customDate",
              "customTextArea",
              "customCheckbox",
              "customSignature",
              "customSequence",
            ],
            [
              {
                name: "custom_container",
                dataDisplay: "container",
                title: "Variables Isthera",
                buttonClass: "",
                innerHTML:
                  '<svg viewBox="0 0 24 24" style="width:24px;height:24px;"><path fill="currentColor" d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>',
              },
            ],
          ],
          font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        }}
      />
    </>
  );
};

export default Form;
