import { getEditorPluginStyleAsText } from "../../css/Editor.js";

export const customPlugins = {
  input_plugin: {
    // @Required @Unique
    // plugin name
    name: "customInput",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie de texte",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("input_plugin");

      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  sequence_plugin: {
    // @Required @Unique
    // plugin name
    name: "customSequence",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Numéro de séquence",
    buttonClass: "",
    innerHTML: '<i class="fas fa-hashtag"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "sequence";
      newNode.placeholder = "Numéro de séquence";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("input_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  client_email_plugin: {
    // @Required @Unique
    // plugin name
    name: "customClientEmail",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du courriel du client",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "client_email";
      newNode.placeholder = "Courriel de client.e";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_phone_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  client_phone_plugin: {
    // @Required @Unique
    // plugin name
    name: "customClientPhone",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du téléphone du client",
    buttonClass: "",
    innerHTML: '<i class="fas fa-phone"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "client_phone";
      newNode.placeholder = "Téléphone de client.e";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_phone_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  client_lastname_plugin: {
    // @Required @Unique
    // plugin name
    name: "customClientLastName",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du prenom du client",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "client_lastname";
      newNode.placeholder = "Prenom de client.e";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_name_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  client_name_plugin: {
    // @Required @Unique
    // plugin name
    name: "customClientName",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du nom du client",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "client_name";
      newNode.placeholder = "Nom de client.e";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_name_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  client_combined_name_plugin: {
    // @Required @Unique
    // plugin name
    name: "customClientFirstNameLastName",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du nom et prénom du client",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "combined_clients_name";
      newNode.placeholder = "Nom et prénom de client.e";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_name_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  technician_name_plugin: {
    // @Required @Unique
    // plugin name
    name: "customTechnicianName",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie du nom technicien.ne",
    buttonClass: "",
    innerHTML: '<i class="fas fa-i-cursor"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customInput = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "input";
      newNode.dataset.command = "technician_name";
      newNode.placeholder = "Nom de technicien.ne";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("client_name_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  date_plugin: {
    // @Required @Unique
    // plugin name
    name: "customDate",
    // @Required
    // data display
    display: "command",

    // @Options
    title: "Saisie de date",
    buttonClass: "",
    innerHTML: '<i class="far fa-calendar"></i>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customDate = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.placeholder = "AAAA-MM-JJ";
      newNode.dataset.type = "date";
      newNode.wrap = "off";
      newNode.rows = "1";
      newNode.style.cssText = getEditorPluginStyleAsText("date_plugin");
      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  textarea_plugin: {
    name: "customTextArea",
    display: "command",
    title: "Zone de texte",
    buttonClass: "",
    innerHTML: '<i class="fas fa-vector-square"></i>',
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customTextArea = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("TEXTAREA");
      newNode.dataset.type = "textarea";
      newNode.placeholder = "Zone de text ici...";
      newNode.style.cssText = getEditorPluginStyleAsText("textarea_plugin");

      this.insertNode(newNode);
      this.nodeChange(null, null, ["TEXTAREA"], true);
    },
  },
  checkbox_plugin: {
    name: "customCheckbox",
    display: "command",
    title: "Case à cocher",
    buttonClass: "",
    innerHTML: '<i class="far fa-check-square"></i>',
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customCheckbox = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const parentNode = this.util.createElement("LABEL");

      parentNode.innerHTML = "Text";
      parentNode.insertAdjacentHTML(
        "afterbegin",
        '<input type="checkbox" disabled=true/>'
      );

      parentNode.onmouseenter = () => {
        parentNode.classList.add("outlined");
      };

      parentNode.onmouseleave = () => {
        parentNode.classList.remove("outlined");
      };

      parentNode.style.cssText = getEditorPluginStyleAsText("checkbox_plugin");

      this.insertNode(parentNode);
      this.nodeChange(null, null, ["LABEL"], true);
    },
  },
  signature: {
    name: "customSignature",
    display: "command",
    title: "Signature",
    buttonClass: "",
    innerHTML: '<i class="fas fa-pen-fancy"></i>',
    add: function (core, targetElement) {
      const context = core.context;

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customSignature = {
        targetButton: targetElement,
      };
    },
    active: function (element) {
      return false;
    },
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
      const newNode = this.util.createElement("BUTTON");
      newNode.innerText = "Signer";
      newNode.dataset.type = "signature";
      newNode.type = "button";
      newNode.disabled = true;
      newNode.style.cssText = getEditorPluginStyleAsText("signature_plugin");

      this.insertNode(newNode);
      this.nodeChange(null, null, ["BUTTON"], true);
    },
  },
};
