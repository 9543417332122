import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AuthProvider from "./context/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./context/LoadingContext/index";
import "./global.css";

const root = createRoot(document.getElementById("app"));
root.render(
  <BrowserRouter>
    <LoadingProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </LoadingProvider>
  </BrowserRouter>
);
