import React, { useState, useContext } from "react";
import styles from "../../css/Login.module.css";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import AuthContext from "../../context/AuthContext";
import SlButton from "@shoelace-style/shoelace/dist/react/button";

const Login = () => {
  const authContext = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validAuth, setValidAuth] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const handlePasswordChange = (e) => {
    setValidAuth(true);
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setValidAuth(true);
    setUsername(e.target.value);
  };

  const handleLoginRequest = async (e) => {
    setLoadingRequest(true);
    e?.preventDefault();
    let response = await authContext.authContext.loginUser(username, password);
    setLoadingRequest(false);

    if (response) {
      window.location.reload();
    } else {
      setValidAuth(false);
    }
  };

  return (
    <div className={styles.main_container}>
      {/* <div className={styles.motd}>
        Bienvenue à Isthera Forms !
        <div>L'outil puissant de création de formulaires web</div>
      </div> */}
      <div className={styles.inner_container}>
        <div className={styles.header}>
          <img src={require("../../images/newlogo-toutseul.png").default} />
        </div>
        <div className={styles.credentials_container}>
          <div
            style={{
              visibility: !validAuth ? "visible" : "hidden",
              color: "red",
              fontWeight: 600,
            }}
          >
            Nom d'utilisateur ou mot de passe invalide
          </div>
          <form onSubmit={handleLoginRequest} autoComplete="off">
            <SlInput
              onInput={handleUsernameChange}
              label="Nom d'utilisateur:"
            />
            <SlInput
              onInput={handlePasswordChange}
              type="password"
              label="Mot de passe:"
              password-toggle
            />
          </form>
        </div>
        {/* <div className={styles.message}>
          <div>Vous ne possedez pas de compte?</div>{" "}
          <div>
            Contactez-nous au (514) 418-8286 ou sans frais au (888) 909-6318.
          </div>
          <div>
            Vous pouvez aussi visiter notre{" "}
            <a href="http://www.posisoft.ca" target="_blank">
              site web
            </a>{" "}
            !
          </div>
        </div> */}
        <div className={styles.footer_buttons}>
          <SlButton
            type="submit"
            onClick={handleLoginRequest}
            loading={loadingRequest}
          >
            Connexion
          </SlButton>
        </div>
      </div>
    </div>
  );
};

export default Login;
