// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V4uqUQl9XqXMGHSlSLKr::part(base){   
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    background-color: tomato;
    color: white;
    font-size: 16px;
}

.hL0WNpC_jApI2SYizkq5{
    color: white;
    display: inline-block;
    margin-right: 16px;
    vertical-align: middle;
    font-size: 16px;
}

.ICYk0DVz1mhr4ghswjds{
    display: flex;
    flex: 1;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/css/App.module.css"],"names":[],"mappings":"AAAA;IAEI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,WAAW;IACX,wBAAwB;IACxB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,OAAO;IACP,aAAa;AACjB","sourcesContent":[".alert::part(base){\r\n    color: white;   \r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    z-index: 999999999;\r\n    width: 100%;\r\n    background-color: tomato;\r\n    color: white;\r\n    font-size: 16px;\r\n}\r\n\r\n.alert_icon_container{\r\n    color: white;\r\n    display: inline-block;\r\n    margin-right: 16px;\r\n    vertical-align: middle;\r\n    font-size: 16px;\r\n}\r\n\r\n.login_container{\r\n    display: flex;\r\n    flex: 1;\r\n    height: 100vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `V4uqUQl9XqXMGHSlSLKr`,
	"alert_icon_container": `hL0WNpC_jApI2SYizkq5`,
	"login_container": `ICYk0DVz1mhr4ghswjds`
};
export default ___CSS_LOADER_EXPORT___;
