import React, { useEffect, useContext, useState } from "react";
import FormCreation from "./components/FormCreation/index";
import { Routes, Route } from "react-router-dom";
import HomeScreen from "./components/HomeScreen";
import FormViewer from "./components/FormViewer";
import Admin from "./components/Admin";
import Login from "./components/Login";
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import AuthContext from "./context/AuthContext.js";
import axios from "axios";
import IstheraHeader from "./components/IstheraHeader";
import { useLocation } from "react-router-dom";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import styles from "./css/App.module.css";
import { useLoading } from "./context/LoadingContext/index";

setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.9.0/cdn/");

const App = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    if (authContext.authContext.isTokenExpired()) {
      authContext.authContext.logoutUser();
      window.location.reload();
    }
  }, []);

  axios.interceptors.request.use(
    (config) => {
      setLoading(true);
      config.headers[
        "Authorization"
      ] = `Token ${authContext.authContext.accessToken?.token}`;

      if (
        authContext.authContext.accessToken &&
        authContext.authContext.isTokenExpired()
      ) {
        authContext.authContext.logoutUser();
        window.location.reload();
      }

      return config;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      setLoading(false);
      return config;
    },
    (error) => {
      if (!axios.isCancel(error)) {
        setLoading(false);
        setAlertMessage(
          error?.response?.data?.errormsg
            ? error.response?.data?.errormsg
            : "Une erreur est survenue"
        );
        setAlertOpen(true);
      }

      return Promise.reject(error);
    }
  );

  const loginPage = () => {
    return (
      <div className={styles.login_container}>
        <Login />
      </div>
    );
  };

  const appPage = () => {
    return (
      <>
        <SlAlert
          className={styles.alert}
          variant="danger"
          duration="4000"
          open={alertOpen}
          closable
          onSlAfterHide={() => setAlertOpen(false)}
        >
          <div className={styles.alert_icon_container}>
            <SlIcon
              color="white"
              className={styles.alert_icon}
              slot="icon"
              name="exclamation-octagon"
            />
          </div>

          {alertMessage}
        </SlAlert>

        {location.pathname === "/" || location.pathname === "/admin" ? (
          <IstheraHeader />
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/new_form" element={<FormCreation />} />
          <Route path="/form" element={<FormViewer />} />
          {authContext.authContext.accessToken.is_super_user ? (
            <Route path="/admin" element={<Admin />} />
          ) : (
            <></>
          )}
        </Routes>
      </>
    );
  };

  return <>{authContext.authContext.loggedIn ? appPage() : loginPage()}</>;
};

export default App;
